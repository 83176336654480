<template>
    <div>
        <div class="card-order c1">
            <div class="row">
                <div class="col-8 order-date">
                    <p class="card-text placeholder-glow">
                        <span class="placeholder col-4"></span><br>
                    </p>
                </div>
            </div>
            <div class="parent">
                <div class="child">
                    <div class="left">
                        <div class="icon-profile">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12" style="height: 50px;"></span><br>
                            </p>
                        </div>
                        <div class="info" style="width: 150px;">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12"></span><br>
                                <span class="placeholder col-12"></span>
                                <span class="placeholder col-12"></span>
                            </p>
                            <p class="cat card-text placeholder-glow">
                                <span class="placeholder col-6"></span><br>
                            </p>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
        <div class="card-order c1">
            <div class="row">
                <div class="col-8 order-date">
                    <p class="card-text placeholder-glow">
                        <span class="placeholder col-4"></span><br>
                    </p>
                </div>
            </div>
            <div class="parent">
                <div class="child">
                    <div class="left">
                        <div class="icon-profile">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12" style="height: 50px;"></span><br>
                            </p>
                        </div>
                        <div class="info" style="width: 150px;">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12"></span><br>
                                <span class="placeholder col-12"></span>
                                <span class="placeholder col-12"></span>
                            </p>
                            <p class="cat card-text placeholder-glow">
                                <span class="placeholder col-6"></span><br>
                            </p>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
        <div class="card-order c1">
            <div class="row">
                <div class="col-8 order-date">
                    <p class="card-text placeholder-glow">
                        <span class="placeholder col-4"></span><br>
                    </p>
                </div>
            </div>
            <div class="parent">
                <div class="child">
                    <div class="left">
                        <div class="icon-profile">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12" style="height: 50px;"></span><br>
                            </p>
                        </div>
                        <div class="info" style="width: 150px;">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12"></span><br>
                                <span class="placeholder col-12"></span>
                                <span class="placeholder col-12"></span>
                            </p>
                            <p class="cat card-text placeholder-glow">
                                <span class="placeholder col-6"></span><br>
                            </p>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
        <div class="card-order c1">
            <div class="row">
                <div class="col-8 order-date">
                    <p class="card-text placeholder-glow">
                        <span class="placeholder col-4"></span><br>
                    </p>
                </div>
            </div>
            <div class="parent">
                <div class="child">
                    <div class="left">
                        <div class="icon-profile">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12" style="height: 50px;"></span><br>
                            </p>
                        </div>
                        <div class="info" style="width: 150px;">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12"></span><br>
                                <span class="placeholder col-12"></span>
                                <span class="placeholder col-12"></span>
                            </p>
                            <p class="cat card-text placeholder-glow">
                                <span class="placeholder col-6"></span><br>
                            </p>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
        <div class="card-order c1">
            <div class="row">
                <div class="col-8 order-date">
                    <p class="card-text placeholder-glow">
                        <span class="placeholder col-4"></span><br>
                    </p>
                </div>
            </div>
            <div class="parent">
                <div class="child">
                    <div class="left">
                        <div class="icon-profile">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12" style="height: 50px;"></span><br>
                            </p>
                        </div>
                        <div class="info" style="width: 150px;">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12"></span><br>
                                <span class="placeholder col-12"></span>
                                <span class="placeholder col-12"></span>
                            </p>
                            <p class="cat card-text placeholder-glow">
                                <span class="placeholder col-6"></span><br>
                            </p>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>