<template>
     <div class="cart-dropdown" :class="openDialogCart ? 'open' : ''" id="cart-dropdown">
          <div class="cart-content-wrap">
               <div class="cart-header">
                    <img style="width: 20%;"
                         src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/logo_footer.png"
                         alt="Profile">
                    <h5 class="header-title">Carrito de compras</h5>
                    <button @click="closeDialog()" class="cart-close sidebar-close"><i class="fas fa-times"></i></button>
               </div>
               <div class="cart-body">
                    <ul v-if="isLoading">
                         <div>
                              <b-spinner variant="danger" class="position-loader" type="grow"
                                   label="Loading..."></b-spinner>
                         </div>
                    </ul>
                    <ul class="cart-item-list" v-if="cart && !isLogged">
                         <li class="cart-item" v-for="currentCart in cart.items" :key="currentCart.id">
                              <div class="item-img" v-if="currentCart.images">
                                   <a :href="currentCart.product_details.simple_fields.url_key" v-if="currentCart.images[0]">
                                        <img :src="currentCart.images[0]['path']"
                                             alt="Commodo Blown Lamp">
                                   </a>
                                   <a :href="currentCart.product_details.simple_fields.url_key" v-else>
                                        <div class="col-6"></div>
                                   </a>
                                   <br>
                                   <a class="close-btn" :href="'#modal' + currentCart.product_details.simple_fields.id">
                                        <i class="fas fa-times"></i>
                                   </a>
                              </div>
                              <div class="item-content">
                                   <h3 class="item-title" style="text-align: right;">
                                        <router-link @click="closeDialog()" :to="{
                                             name: 'Show',
                                             params: { 'id': currentCart.product_details.simple_fields.url_key }
                                        }">
                                             {{ currentCart.name }}
                                        </router-link>
                                   </h3>
                                   <div class="row">
                                        <div class="col-6">
                                             <div class="pro-qty item-quantity">
                                                  <span class="dec qtybtn" @click="updateShoppingCart(currentCart.id, currentCart.quantity)">-</span>
                                                  <input type="number"
                                                       class="quantity-input" :value="currentCart.quantity">
                                                  <span class="inc qtybtn" @click="add(currentCart.product_id)">+</span>
                                             </div>
                                        </div>
                                        <div class="col-6">
                                             <div class="item-price" style="text-align: right;" v-if="currentCart"><span
                                                       class="currency-symbol"></span>{{
                                                            $filters.money(currentCart.base_price * currentCart.quantity) }}</div>
                                        </div>
                                   </div>
                              </div>
                              <div class="modal" :id="'modal' + currentCart.product_details.simple_fields.id">
                                   <a href="#" class="modal-bg"></a>
                                   <div class="modal-content">
                                        <a href="#" class="modal-exit"><i class="fas fa-times"></i></a>
                                        <h3 class="modal-title axil-breadcrumb-item">¿Eliminar producto del carrito?</h3>
                                        <div class="modal-text">
                                             <div class="row">
                                                  <div class="col-6">
                                                       <b-button variant="light">
                                                            <a class="cancel-modal" href="#">Cancelar</a>
                                                       </b-button>
                                                  </div>
                                                  <div class="col-6">
                                                       <b-button variant="success"
                                                            @click="removeItem(currentCart.id, currentCart.product_details.simple_fields.url_key)">Aceptar</b-button>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </li>
                    </ul>
                    <ul v-if="!cart && !isLogged">
                         <li class="cart-item">
                              Tu carrito esta vacío.
                         </li>
                    </ul>
                    <ul v-if="productsTemp.length === 0 && isLogged">
                         <li class="cart-item">
                              Tu carrito esta vacío.
                         </li>
                    </ul>
                    <ul class="cart-item-list" v-if="productsTemp.length > 0 && isLogged">
                         <li class="cart-item" v-for="currentCart in productsTemp" :key="currentCart.id">
                              <div class="item-img" v-if="currentCart.products.images">
                                   <a :href="currentCart.products.simple_fields.url_key" v-if="currentCart.products.images[0]">
                                        <img :src="currentCart.products.images[0]['path']"
                                             alt="Commodo Blown Lamp">
                                   </a><br>
                                   <a class="close-btn" :href="'#modal' + currentCart.products.simple_fields.id">
                                        <i class="fas fa-times"></i>
                                   </a>
                              </div>
                              <div class="item-content">
                                   <h3 class="item-title" style="text-align: right;">
                                        <router-link @click="closeDialog()" :to="{
                                             name: 'Show',
                                             params: { 'id': currentCart.products.simple_fields.url_key }
                                        }">
                                             {{ currentCart.products.simple_fields.name }}
                                        </router-link>
                                   </h3>
                                   <div class="row">
                                        <div class="col-6">
                                             <div class="pro-qty item-quantity">
                                                  <span class="dec qtybtn" @click="addProductTemp(-1, currentCart.products.id, currentCart.quantity)">-</span>
                                                  <input type="number" @change="updateQuantityCart(currentCart.id, this)"
                                                       class="quantity-input" :value="currentCart.quantity">
                                                  <span class="inc qtybtn" @click="addProductTemp(1, currentCart.products.id, currentCart.quantity)">+</span>
                                             </div>
                                        </div>
                                        <div class="col-6">
                                             <div class="item-price" style="text-align: right;" v-if="currentCart">
                                                  <span class="currency-symbol"></span>
                                                  {{ $filters.money(currentCart.products.inventory.mb_price * currentCart.quantity) }}
                                             </div>
                                        </div>
                                   </div>
                                   <div class="modal" :id="'modal' + currentCart.products.simple_fields.id">
                                        <a href="#" class="modal-bg"></a>
                                        <div class="modal-content">
                                             <a href="#" class="modal-exit"><i class="fas fa-times"></i></a>
                                             <h3 class="modal-title axil-breadcrumb-item">¿Eliminar producto del carrito?
                                             </h3>
                                             <div class="modal-text">
                                                  <div class="row">
                                                       <div class="col-6">
                                                            <b-button variant="light">
                                                                 <a class="cancel-modal" href="#">Cancelar</a>
                                                            </b-button>
                                                       </div>
                                                       <div class="col-6">
                                                            <b-button variant="success"
                                                                 @click="deleteProductCartTemp(currentCart.products.id)">Aceptar</b-button>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </li>
                    </ul>
               </div>
               <div class="cart-footer" v-if="isLogged">
                    <h3 class="cart-subtotal">
                         <span class="subtotal-title">Subtotal:</span>
                         <span class="subtotal-amount" v-if="totalPrice > 0 && isLogged">{{ $filters.money(totalPrice)
                         }}</span>
                         <span class="subtotal-amount" v-if="totalPrice == 0 && isLogged">$0.00</span>
                    </h3>
                    <div class="group-btn">
                         <router-link :to="{ name: 'Checkout-Cart' }" @click="closeDialog"
                              class="axil-btn btn-bg-primary viewcart-btn">Ver carrito</router-link>
                         <router-link :to="{ name: 'Checkout' }" class="axil-btn btn-bg-secondary checkout-btn">Proceder al
                              pago</router-link>
                    </div>
               </div>
               <div class="cart-footer" v-if="cart && !isLogged">
                    <h3 class="cart-subtotal">
                         <span class="subtotal-title">Subtotal:</span>
                         <span class="subtotal-amount" v-if="cart.base_grand_total && !isLogged">{{
                              $filters.money(cart.base_grand_total)
                         }}</span>
                    </h3>
                    <div class="group-btn text-center">
                         <!--router-link :to="{ name: 'Checkout-Cart' }" @click="closeDialog"
                              class="axil-btn btn-bg-primary viewcart-btn">Ver carrito</router-link-->
                         <b-button style="margin: auto" variant="danger" @click="processOrder()" class="axil-btn btn-bg-secondary checkout-btn">Proceder al
                              pago</b-button>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { useToast } from "vue-toastification";
import store from '@/store'
import { mapState } from 'vuex'

export default {
     name: "CartDropdown",
     props: {
          openDialogCart: {
               type: Boolean,
               required: true
          },
          cart: {
               required: false,
               type: Object
          },
     },
     data: () => ({
          toast: useToast(),
          qty: ''
     }),
     computed: {
          isLogged() {
               if (!store.state.user.token) {
                    return true;
               }
               return false;
          },
          ...mapState(['isLoading', 'productsTemp', 'productsCartTemp'])
     },
     methods: {
          totalPriceTemp() {
               this.totalPrice = this.productsTemp.reduce((a, b) => a + parseFloat(b.products.inventory.mb_price * b.quantity), 0);
          },
          closeDialog() {
               this.$emit('changeDialogCart')

          },
          removeItem(itemId, prodName) {
               this.isLoading = true
               store.dispatch('removeItemFromCart', {
                    prodId: prodName,
                    itemId: itemId
               })
                    .then(() => {
                         store.dispatch('getCart');
                         this.toast.success("Producto eliminado correctamente")
                    }).catch(() => {
                         this.toast.error("Ha ocurrido un error, intentalo más tarde")
                    });
          },
          updateQuantityCart(idCart, event) {
               console.log(idCart, event.target);
          },
          deleteProductCartTemp(product) {
               const index = this.productsTemp.findIndex(c => c.id = product)
               if (index > -1)
                    this.productsTemp.splice(index, 1)
               const index2 = this.productsCartTemp.findIndex(c => c.productId = product)
               if (index2 > -1)
                    this.productsCartTemp.splice(index2, 1)
               window.location.href = '#';
               this.toast.success("Producto eliminado correctamente")
          },
          addProductTemp(NewQuantity, productId, qty) {
               let exists = false
               this.productsCartTemp.forEach((prod) => {
                    if (prod.productId == productId) {
                         exists = true
                    }
               });

               if (NewQuantity > qty && exists === false) {
                    this.productsTemp.push({ products: this.product, quantity: qty })
                    this.productsCartTemp.push({ productId: productId, quantity: qty })
                    this.toast.success('Articulo añadido al carrito')
               }
               if (exists === false && NewQuantity < qty) {
                    this.productsTemp.push({ products: this.product, quantity: parseInt(NewQuantity) })
                    this.productsCartTemp.push({ productId: productId, quantity: parseInt(NewQuantity) })
                    this.toast.success('Articulo añadido al carrito')
               }
               if (exists === true) {
                    this.productsTemp.filter(product => {
                         if (product.products.id == productId) {
                              this.total = product.quantity += parseInt(NewQuantity)
                         }
                    })
                    let totalQty = this.total;
                    this.updateProductsTemp(productId, totalQty);
                    this.toast.success('Articulo añadido al carrito')
               }
          },
          updateProductsTemp(productId, qty) {
               const indiceElemento = this.productsTemp.findIndex(c => c.products.id == productId)
               let newProductsTemp = [...this.productsTemp]
               newProductsTemp[indiceElemento] = { ...newProductsTemp[indiceElemento], quantity: this.total }
               this.productsCartTemp.splice(0, this.productsCartTemp.length)
               this.productsTemp.forEach((prod) => {
                    if(this.total > qty){
                         this.productsCartTemp.push({ productId: prod.products['id'], quantity: qty })
                    }else{
                         this.productsCartTemp.push({ productId: prod.products['id'], quantity: prod['quantity'] })
                    }
               });             
          },
          updateShoppingCart(productId, productQty) {
               this.isLoading = true
               let qtyCart = productQty - 1;
               const qty = {
                    [productId]: qtyCart
               };

               store.dispatch('updateShoppingCart',{
                    qty
               })
                    .then((res) => {
                         console.log("update cart",res)
                         store.dispatch('getCart');
                         this.toast.success('Carrito actualizado correctamente')

                    }).catch(() => {
                         this.toast.error("Ha ocurrido un error, intentalo más tarde")
                    })
          },
          add(productId){
               this.isLoading = true
               store.dispatch("addProductToCart", {
                         quantity: 1,
                         product_id: productId,
                         is_buy_now: 0,
                         inventory_id: 0
                    })
               .then(() => {
                    console.log("token",store.state.user.token)
                    store.dispatch("getCart")
                    this.toast.success('Carrito actualizado correctamente')

               }).catch((err) => {
                    console.log(err);
               });
          },
          processOrder(){
               store.dispatch('processOrder',  { 
                    mobile: true,
                    user_id : store.state.user.data.id
                })
               .then((res) => {
                    //console.log("ext", res)
                    return this.$router.push(res.request.responseURL); 
               }).catch((err) => {
                    this.toast.error("Ha ocurrido un error, intentalo más tarde"+err.response)
               });
          }
     }
}
</script>