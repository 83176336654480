<template>
     <div>
          <div v-if="product.type === 'simple'">
               <SimpleProduct :product="product" :images="images"></SimpleProduct>
          </div>
     </div>
</template>
<script>
import store from '@/store'
import SimpleProduct from '@/components/Products/SimpleProduct.vue'
     export default {
          components: { SimpleProduct },
          props:['id'],
          name: "ProductShow",
          data: () => ({
               product: [],
               images: []
          }),
          methods: {
          },
          mounted(){
               store.dispatch('showProduct', this.$route.params.id)
               .then((res) => {
                    this.product = res.data.product;
                    this.images = res.data.product.images;
               }).catch((err) => {
                    console.log(err);
                    this.$router.push({name: 'NotFound'})
               });
          }
     }
</script>