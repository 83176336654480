<template>
    <div class="wrapper-main-mb">
        <!-- Start Breadcrumb Area  -->
        <div class="axil-breadcrumb-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-8">
                        <div class="inner">
                            <ul class="axil-breadcrumb">
                                <li class="axil-breadcrumb-item"><router-link :to="{ name: 'Profile' }">MI
                                        CUENTA</router-link>
                                </li>
                                <li class="separator"></li>
                                <li class="axil-breadcrumb-item active" aria-current="page">FAVORITOS</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="tab-pane" id="nav-address" role="tabpanel" style="padding: 0 20px;">
            <div class="axil-dashboard-address">
                <div v-if="isLoading">
                    <PlaceHolder></PlaceHolder>
                </div>
                <div v-else class="card-order c1" v-for="(wishlist, wish) in products" :key="wish">
                    <div class="parent">
                        <div class="child">
                            <div class="left">
                                <div v-for="(img, ix) in wishlist.product_details.images.slice(0, 1)" :key="ix">
                                    <router-link v-if="img.path" :to="{ name: 'Show', params: { 'id': wishlist.product_details.simple_fields.url_key } }">
                                        <img data-sal-delay="200" data-sal-duration="800" loading="lazy" class="main-img"
                                        :src="img.path"
                                        alt="Product Images">
                                    </router-link>
                                </div>
                                <div class="info">
                                    <router-link style="text-decoration: none;"
                                        :to="{ name: 'Show', params: { 'id': wishlist.product_details.simple_fields.url_key } }">
                                        <div style="width: 150px;">
                                            <h3 class="data name-order text-dark">{{
                                                wishlist.product_details.simple_fields.name }}</h3>
                                        </div><br>
                                        <!--h3 class="data" v-if="wishlist.inventory.mb_special_price">
                                            <span class="old-price">{{ $filters.money(wishlist.inventory.mb_price)
                                            }}</span><br>
                                            {{ $filters.money(wishlist.inventory.mb_special_price) }}
                                        </h3>
                                        <h3 class="data" v-else>
                                            {{ $filters.money(wishlist.inventory.mb_price) }}
                                        </h3-->
                                    </router-link>
                                </div>
                            </div>
                            <div style="width: 15%;">
                                <b-button>
                                    <a :href="'#modal' + wishlist.product_details.id"><i class="far fa-heart-broken"></i></a>
                                </b-button>
                                <b-button  @click.prevent="add(wishlist.product_details.id)" style="background: #164170;color: #fff;font-size: 16px;">
                                    <i class="fal fa-shopping-cart"></i>
                                </b-button>

                            </div>
                        </div>
                    </div>
                    <div class="modal" :id="'modal' + wishlist.product_details.id">
                        <a href="#" class="modal-bg"></a>
                        <div class="modal-content">
                            <a href="#" class="modal-exit"><i class="fas fa-times"></i></a>
                            <h3 class="modal-title axil-breadcrumb-item">¿Eliminar dirección?</h3>
                            <div class="modal-text">
                                <div class="row">
                                    <div class="col-6">
                                        <b-button variant="light">
                                            <a class="cancel-modal" href="#">Cancelar</a>
                                        </b-button>
                                    </div>
                                    <div class="col-6">
                                        <b-button variant="success"
                                            @click.prevent="removeItem(wishlist.product_details.id)">Aceptar</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info" v-if="products.length === 0">
                    <h3 class="data name-order text-dark text-center">No hay productos aún</h3>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from "vuex";
import store from "@/store";
import { useRouter } from "vue-router";
import { mapState } from "vuex";
import PlaceHolder from '@/components/PlaceHolder/WishList.vue'
import { useToast } from "vue-toastification";

export default {
    data: () => ({
        store: useStore(),
        router: useRouter(),
        user: {},
        products: [],
        toast: useToast(),
    }),
    components: {
        PlaceHolder
    },
    computed: {
        ...mapState(['isLoading'])
    },
    mounted() {
        this.getWishlist();
    },
    methods: {
        getWishlist() {
            if (store.state.user.token) {
                store.dispatch('getWishlist')
                    .then(response => {
                        this.products = response.data.wishlist
                    })
                    .catch(error => {
                        console.error('Error fetching getWishlist:', error);
                    });
            }
        },
        removeItem(productId) {
            store.dispatch('removeProductFromWishlist', {
                productId: productId,
            })
                .then(() => {
                    this.toast.success("Artículo eliminado de la lista de deseos")
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.getWishlist();
                });
        },
        add(productId) {
               if (store.state.user.token) {
                    store.dispatch("addProductToCart", {
                         quantity: 1,
                         productId: productId
                    })
                         .then((res) => {
                              store.dispatch("getCart")
                              this.toast.success(res.data.messageForUser)

                         }).catch((err) => {
                              console.log(err);
                         });
               } else {
                    this.toast.error('Inicia sesion para poder realizar esta accion')
               }
          },
    }
}
</script>
<style>
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #164170;
    --bs-btn-border-color: none;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: none;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: none;
}

.boton-heart {
    background: transparent;
    border: none;
    font-size: 30px;
}

.btn-secondary {
    background: transparent;
    color: #777780;
    border: none;
    font-size: 16px;
    padding: 1px 12px 3px;
    border: 2px solid var(--color-light);
    border-radius: 6px;
    margin-bottom: 4px;
}</style>