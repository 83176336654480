<template>
    <div class="wrapper-main-mb">
        <!-- Start Breadcrumb Area  -->
        <div class="axil-breadcrumb-area">
        </div>
        <div class="tab-pane" id="nav-orders" role="tabpanel" style="padding: 20px 20px;">
            <div class="axil-dashboard-order">
                <div class="product-table-heading">
                    <h1 class="title">Detalle del pedido.</h1>
                </div>
            </div>
        </div>
    </div>
</template>