<template>
     <div>
          <main class="main-wrapper">
               <!-- Start Cart Area  -->
               <div class="axil-product-cart-area axil-section-gap">
                    <div class="container">
                    <div class="axil-product-cart-wrap">
                         <div class="product-table-heading">
                              <h4 class="title">Tu Carrito de compras</h4>
                              <!-- <a href="#" class="cart-clear">Clear Shoping Cart</a> -->
                         </div>
                         <div class="table-responsive">
                              <table class="table axil-product-table axil-cart-table mb--40">
                                   <thead>
                                        <tr>
                                        <th scope="col" class="product-remove"></th>
                                        <th scope="col" class="product-thumbnail">Product</th>
                                        <th scope="col" class="product-title"></th>
                                        <th scope="col" class="product-price">Price</th>
                                        <th scope="col" class="product-quantity">Quantity</th>
                                        <th scope="col" class="product-subtotal">Subtotal</th>
                                        </tr>
                                   </thead>
                                   <tbody>
                                        <tr v-for="(currentCart, index) in cart.items" :key="index">
                                             <td class="product-remove"><button class="close-btn" @click="removeItem(currentCart.product_id, currentCart.quantity)"><i
                                                            class="fal fa-times"></i></button></td>
                                             <td class="product-thumbnail" v-if="currentCart.images">
                                                  <img :src="currentCart.images[0].path"
                                                            alt="Digital Product"></td>
                                             <td class="product-title"><router-link :to="{name: 'Show', params: { 'id': currentCart.product_details.simple_fields.url_key } }">
                                                       {{ currentCart.name }}
                                                  </router-link></td>
                                             <td class="product-price" data-title="Price"><span
                                                       class="currency-symbol"></span>{{ $filters.money(currentCart.base_price) }}</td>
                                             <td class="product-quantity" data-title="Cantidad">
                                                  <div class="pro-qty">
                                                       <input type="number" class="quantity-input" :value="currentCart.quantity">
                                                  </div>
                                             </td>
                                             <td class="product-subtotal" data-title="Subtotal"><span
                                                       class="currency-symbol"></span>{{ $filters.money(cart.base_grand_total) }}
                                             </td>
                                        </tr>
                                        <tr>
                                             
                                        </tr>
                                   </tbody>
                              </table>
                              <b-button variant="danger" @click="processOrder()" style="background: #ff497c;">Proceder al pago</b-button>
                         </div>
                         <!--div class="cart-update-btn-area">
                              <div class="input-group product-cupon form-group">
                                   <input placeholder="Ingresa tu cupon de descuento" v-model="coupon" type="text">
                                   <div class="product-cupon-btn">
                                        <button @click="applyCoupon" class="axil-btn btn-outline">Aplicar</button>
                                   </div>
                              </div>
                              <div class="update-btn">
                                   <a href="#" class="axil-btn btn-bg-primary">Update Cart</a>
                              </div>
                         </div>
                         <div class="row">
                              <div class="col-xl-5 col-lg-7 offset-xl-7 offset-lg-5">
                                   <div class="axil-order-summery mt--80" style="background-color: #d9d9d95c;">
                                        <h5 class="title mb--20">Resumen</h5>
                                        <div class="summery-table-wrap">
                                             <table class="table summery-table mb--30">
                                                  <tbody>
                                                       <tr class="order-subtotal">
                                                            <td>Subtotal</td>
                                                            <td>{{ $filters.money(cart.base_grand_total) }}</td>
                                                       </tr>
                                                       <tr class="order-subtotal">
                                                            <td class="product-title">Cupon:</td>
                                                            <td>
                                                                 <tr>
                                                                      <div class="d-flex align-items-center">
                                                                           <div>
                                                                                Cupon 
                                                                           </div>
                                                                           <div class="ps-4">
                                                                                <button class="axil-btn btn-bg-secondary" 
                                                                                @click="removeCoupon()">Quitar cupon</button>
                                                                           </div>
                                                                      </div>
                                                                 </tr>
                                                                 
                                                            </td>
                                                       </tr>
                                                       <tr class="order-total">
                                                            <td>Total</td>
                                                            <td class="order-total-amount">{{ $filters.money(cart.grand_total) }}</td>
                                                       </tr>
                                                  </tbody>
                                             </table>
                                        </div>
                                        <router-link :to="{name: 'Checkout'}" class="axil-btn btn-bg-primary checkout-btn">Proceder al pago</router-link>
                                   </div>
                              </div>
                         </div-->
               </div>
               </div>
          </div>
          <!-- End Cart Area  -->
     </main>
     </div>
</template>
<script>
import store from '@/store'
import { useToast } from "vue-toastification";

export default {
     name: 'CartView',
     data: () => ({
          cart: [],
          toast: useToast(),
          coupon: "",
     }),
     methods: {
          removeItem(productId, quantity){
               store.dispatch('removeItemFromCart', {
                    productId: productId,
                    quantity: quantity
               })
               .then(() => {
                    this.toast.success("Articulo eliminado del carrito")
                    store.dispatch('getCart');
                    
               }).catch(() => {
                    this.toast.error("Ha ocurrido un error, intentalo más tarde")
               });
          },
          applyCoupon() {
               if(!this.coupon){
                    this.toast.error("Añade un cupon valido")
                    return;
               }
               store.dispatch('applyCoupon', {
                    coupon: this.coupon
               })
               .then((res) => {
                    this.toast.success(res.data.messageForUser);
               }).catch((err) => {
                    this.toast.error("Ha ocurrido un error, intentalo más tarde"+err.response.data.message)
               });
          },
          removeCoupon() {
               store.dispatch('removeCoupon', {
                    coupon: 'Cupon'
               })
               .then((res) => {
                    this.toast.success(res.data.messageForUser);
               }).catch((err) => {
                    this.toast.error("Ha ocurrido un error, intentalo más tarde"+err.response)
               });
          },
          processOrder(){
               store.dispatch('processOrder',  { 
                    mobile: true,
                    user_id : store.state.user.data.id
                })
               .then((res) => {
                    console.log("ext", res.request.responseURL)
                    //return this.$router.push(res.request.responseURL); 
               }).catch((err) => {
                    this.toast.error("Ha ocurrido un error, intentalo más tarde"+err.response)
               });
          }
     },
     created() {
          setTimeout( () => {
               this.cart = store.state.user.cart
          }, 2000)
     }
}
</script>
<style scoped>

</style>