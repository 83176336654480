<template>
    <div class="wrapper-main-mb">
        <!-- Start Breadcrumb Area  -->
        <div class="axil-breadcrumb-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-8">
                        <div class="inner">
                            <ul class="axil-breadcrumb">
                                <li class="axil-breadcrumb-item"><router-link :to="{ name: 'Profile' }">MI
                                        CUENTA</router-link>
                                </li>
                                <li class="separator"></li>
                                <li class="axil-breadcrumb-item active" aria-current="page">DIRECCIONES</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="tab-pane" id="nav-address" role="tabpanel" style="padding: 0 20px;">
            <div class="axil-dashboard-address">
                <div class="row row--30">
                    <div v-if="isLoading">
                        <PlaceHolder></PlaceHolder>
                    </div>
                    <div v-else class="col-lg-6" v-for="(address, adr) in addresses" :key="adr">
                        <div class="card-order c1" :class="{ 'address': address.default_address === 1 }">
                            <div class="default-address" v-if="address.default_address === 1"><b>Destino</b></div>
                            <div class="ship-address" v-else><b>Elegir destino</b></div>
                            <div class="parent">
                                <div class="child">
                                    <div class="left">
                                        <div class="icon-profile">
                                            <a href="#" class="address-edit">
                                                <i style="font-size: 25px;" class="far fa-edit"></i>
                                            </a>
                                        </div>
                                        <div class="info">
                                            <h3 class="data"><b>{{ address.first_name }} {{ address.last_name }}</b></h3>
                                            <br>
                                            <h3 class="data" style="color:#6396cb">Tel: {{ address.phone }}</h3>
                                            <h3 class="data">
                                                {{ address.address1 }} {{ address.address2 }}, <br>{{ address.city }}
                                                {{ address.state }}, {{ address.postcode }}
                                            </h3>
                                        </div>
                                    </div>
                                    <div class="delete-address">
                                        <a :href="'#modal'+ address.id" class="cancel-modal">
                                            <i class="far fa-trash"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div><br>
                        <div class="modal" :id="'modal'+ address.id">
                            <a href="#" class="modal-bg"></a>
                            <div class="modal-content">
                                <a href="#" class="modal-exit"><i class="fas fa-times"></i></a>
                                <h3 class="modal-title axil-breadcrumb-item">¿Eliminar dirección?</h3>
                                <div class="modal-text">
                                    <div class="row">
                                        <div class="col-6">
                                            <b-button variant="light">
                                                <a class="cancel-modal" href="#">Cancelar</a>
                                            </b-button>
                                        </div>
                                        <div class="col-6">
                                            <b-button variant="success" @click.prevent="removeItem(address.id)">Aceptar</b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="info" v-if="addresses.length === 0">
                        <h3 class="data name-order text-dark text-center">No hay direcciones guardadas.</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
import store from "@/store";
import { useRouter } from "vue-router";
import PlaceHolder from '@/components/PlaceHolder/MyAddresses.vue'
import { mapState } from "vuex";
import { useToast } from "vue-toastification";

export default {
    data: () => ({
        store: useStore(),
        router: useRouter(),
        user: {},
        addresses: [],
        toast: useToast(),
    }),
    components: {
        PlaceHolder
    },
    computed: {
        ...mapState(['isLoading'])
    },
    created() {
        if (store.state.user.token) {
            this.getUserData()
        } else {
            this.user = computed(() => '');
            if (store.state.user.tokenFresh) {
                store.dispatch("refreshToken")
                    .then((res) => {
                        console.log(res);
                    }).catch((err) => {
                        console.log(err);
                    });
            }
        }
    },
    methods: {
        getUserData() {
            store.dispatch("getUser").then(() => {
                console.log("user", this.store.state.user.data)
                this.addresses = this.store.state.user.data.addresses;
                if (this.store.state.user.token) {
                    this.user = computed(() => store.state.user.data);

                } else {
                    this.user = computed(() => '');
                }
            })
                .catch((err) => {
                    console.log(err)
                })
        },
        removeItem(address_id) {
            store.dispatch('deleteAddress', {
                address_id: address_id,
            })
                .then((res) => {
                    this.toast.success(res.data.messageForUser)
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.getUserData()
                });
        },
    }
}
</script>
<style>
.address {
    background: #f0efef;
}
/* Modal container*/
.modal {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all .7s;
    transition: all .7s;
}

/* Modal container gets target and it is shown and background modal too*/
.modal:target,
.modal:target .modal-bg {
    display: block;
    z-index: 100;
    opacity: 1;
    visibility: visible;
}

/* Background modal*/
.modal-bg:active,
.modal-bg:hover,
.modal-bg:visited,
.modal-bg:link {
    text-decoration: none;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-transition: all .7s;
    transition: all .7s;
    cursor: default;
}

/* Background modal overlaps to container*/
.modal:target .modal-bg {
    z-index: 200;
}

/* Modal content or body*/
.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: white;
    width: 60%;
    height: auto;
    /*border-radius: 2rem;*/
    text-align: center;
    z-index: 300;
}

.modal-title {
    font-family: var(--font-secondary);
    margin-top: 5vh;
    margin-right: 5vh;
    margin-left: 5vh;
    margin-bottom: 1.2rem;
}

.modal-text {
    margin-bottom: 5vh;
    margin-right: 5vh;
    margin-left: 5vh;
}

/* Modal is closed at lose target*/
.modal-exit:link,
.modal-exit:active,
.modal-exit:visited,
.modal-exit:hover {
    position: absolute;
    top: 5%;
    right: 5%;
    font-size: 1.7rem;
    text-decoration: none;
    color: #a5a3a3;
}

.cancel-modal {
    text-decoration: none;
    color: #000;
}</style>