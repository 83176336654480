<template>
    <div class="wrapper-main-mb">
        <!-- Start Breadcrumb Area  -->
        <div class="axil-breadcrumb-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-8">
                        <div class="inner">
                            <ul class="axil-breadcrumb">
                                <li class="axil-breadcrumb-item"><router-link :to="{ name: 'Profile' }">MI
                                        CUENTA</router-link>
                                </li>
                                <li class="separator"></li>
                                <li class="axil-breadcrumb-item active" aria-current="page">MIS PEDIDOS</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <!-- End Breadcrumb Area  -->
        <div v-if="isLoading">
            <PlaceHolder></PlaceHolder>
        </div>
        <div v-else class="tab-pane" id="nav-orders" role="tabpanel" style="padding: 0 20px;">
            <div class="axil-dashboard-order">
                <div class="card-order c1" v-for="(order, odr) in orders" :key="odr">
                    <router-link :to="{ name: 'Order Detail', params: { 'id': order.id } }" class="text-dark router-link">
                        <div class="number-order"><b>#{{ order.id }}</b></div>
                        <div class="row">
                            <div class="col-8 order-date">{{ $filters.inDate(order.created_at) }}</div>
                            <div class="col-4"><b class="order-status">{{ order.status }}</b></div>
                        </div>
                        <div class="parent">
                            <div class="child" v-for="(product, prod) in order.items" :key="prod">
                                <div class="left">
                                    <div class="icon-profile" v-if="product.images[0]">
                                        <img :src="product.images[0].path">
                                    </div>
                                    <div class="info" style="width: 70%;">
                                        <h5 class="name name-order" style="font-size: 12px;">{{ product.name }}</h5>
                                        <h5 class="name name-order" style="color:#6396cb" v-if="product.inventory.mb_special_price">
                                            <span class="old-price">{{ $filters.money(product.inventory.mb_price) }}</span><br>
                                            {{ $filters.money(product.inventory.mb_special_price) }}
                                        </h5>
                                        <h5 class="name name-order" style="color:#6396cb" v-else>
                                            {{ $filters.money(product.inventory.mb_price) }}
                                        </h5>
                                    </div>
                                </div><br>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
import store from "@/store";
import { useRouter } from "vue-router";
import PlaceHolder from '@/components/PlaceHolder/MyOrders.vue'
import { mapState } from 'vuex'

export default {
    data: () => ({
        store: useStore(),
        router: useRouter(),
        user: {},
        orders: [],
    }),
    components: {
        PlaceHolder
    },
    created() {
        if (store.state.user.token) {
            this.getUserData()
        } else {
            this.user = computed(() => '');
            if (store.state.user.tokenFresh) {
                store.dispatch("refreshToken")
                    .then((res) => {
                        console.log(res);
                    }).catch((err) => {
                        console.log(err);
                    });
            }
        }
    },
    computed:{
        ...mapState(['isLoading'])
    },
    methods: {
        getUserData() {
            store.dispatch("getUser").then(() => {
                this.orders = this.store.state.user.data.orders;
                if (this.store.state.user.token) {
                    this.user = computed(() => store.state.user.data);

                } else {
                    this.user = computed(() => '');
                }
            })
            .catch((err) => {
                console.log(err)
            })
        },
    }
}
</script>
