<template>
    <div>
        <div id="axil-sticky-placeholder"></div>
        <div class="axil-mainmenu">
            <div class="container">
                <div class="header-navbar" style="display: block !important">
                    <div class="header-brand">
                        <router-link :to="{ name: 'Index' }" class="logo logo-dark">
                            <img src="@/assets/images/logo/logo.jpeg" alt="Site Logo">
                        </router-link>
                        <a href="/" class="logo logo-light">
                            <img src="@/assets/images/logo/logo.jpeg" alt="Site Logo">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Mainmenu Area -->
        <main class="main-wrapper">
            <router-view :key="$route.path"></router-view>  
        </main>
    </div>
</template>
<script>
import store from '@/store/index.js'
import { useStore } from "vuex";
import { computed } from "vue";

export default {
    data: () => ({
        store: useStore(),
        user: {},
        cart: {},

    }),
    methods: {
        getUserData() {
            store.dispatch("getUser").then(() => {
                    if (this.store.state.user.token) {
                        this.user = computed(() => store.state.user.data);
                    } else {
                        this.user = computed(() => '');
                    }
                })
                .catch( (err) => {
                    console.log(err)
                })
            store.dispatch("getCart")
                .then( () => {
                    this.cart = computed( () => store.state.user.cart)
                }).catch((err) => {
                    console.log(err);
                });
        }
    },
    created() {
        if (store.state.user.token) {
            this.getUserData()
        } else {
            // this.user = computed(() => '');
            // if(store.state.user.tokenFresh){
            //     store.dispatch("refreshToken")
            //     .then((res) => {
            //     console.log(res);
            //     }).catch((err) => {
            //     console.log(err);
            //     });
            // }
        }
    },
}
</script>