<template>
    <div>
        <div class="axil-product product-style-one mb--30">
            <div class="thumbnail">
                <!--GallerySlider :items="prod.images" / -->
                <p class="card-text placeholder-glow" style="padding: 0 40px;">
                    <span class="placeholder col-12" style="height: 200px;"></span><br>
                </p>
            </div>
            <div class="product-content">
                <div class="inner">
                    <p class="card-text placeholder-glow" style="padding: 0 20px;">
                        <span class="placeholder col-12"></span><br>
                        <span class="placeholder col-12"></span><br>
                        <span class="placeholder col-12"></span><br>
                        <span class="placeholder col-12"></span><br>
                    </p>
                    <div class="product-price-variant"><br>
                        <p class="card-text placeholder-glow">
                            <span class="placeholder col-6"></span><br>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>