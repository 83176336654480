<template>
    <div>
        <div class="row row--50">
            <div class="col-lg-6 mb--40">
                <div class="row">
                    <div class="col-lg-12">
                        <p class="card-text placeholder-glow" style="padding: 0 40px;margin-top: 10%;">
                            <span class="placeholder col-12" style="height: 200px;"></span><br>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mb--40">
                <div class="single-product-content">
                    <div class="inner">
                        <p class="card-text placeholder-glow" style="padding: 0 20px;">
                            <span class="placeholder col-12"></span><br>
                            <span class="placeholder col-12"></span><br>
                            <span class="placeholder col-12"></span><br>
                            <span class="placeholder col-12"></span><br>
                        </p>
                        <span class="price-amount">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-4"></span><br>
                            </p>
                        </span>
                        <p class="card-text placeholder-glow" style="padding: 0 20px;">
                            <span class="placeholder col-12"></span><br>
                        </p>
                        <ul class="product-meta" style="text-align: left;">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-4"></span><br>
                            </p>
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-6"></span><br>
                            </p>
                        </ul>
                        <p class="card-text placeholder-glow" style="padding: 0 20px;">
                            <span class="placeholder col-6" style="height: 40px;"></span>&nbsp;&nbsp;&nbsp;
                            <span class="placeholder col-2" style="height: 40px;"></span><br>
                        </p>
                        <div class="product-variations-wrapper">
                            <br><br>
                            <p class="card-text placeholder-glow" style="text-align: left;padding: 0 20px;">
                                <span class="placeholder col-3"></span><br>
                            </p>
                            <p class="card-text placeholder-glow" style="padding: 0 20px;">
                                <span class="placeholder col-12"></span><br>
                                <span class="placeholder col-12"></span><br>
                                <span class="placeholder col-12"></span><br>
                                <span class="placeholder col-12"></span><br>
                            </p>
                        </div>

                        <div class="product-desc-wrapper pt--80 pt_sm--60">
                            <p class="card-text placeholder-glow" style="padding: 0 20px;">
                                <span class="placeholder col-4"></span><br>
                            </p>
                            <div class="single-desc mb--30">
                                <p class="card-text placeholder-glow" style="padding: 0 20px;">
                                    <span class="placeholder col-12"></span><br>
                                    <span class="placeholder col-12"></span><br>
                                    <span class="placeholder col-12"></span><br>
                                    <span class="placeholder col-12"></span><br>
                                    <span class="placeholder col-12"></span><br>
                                    <span class="placeholder col-12"></span><br>
                                    <span class="placeholder col-12"></span><br>
                                    <span class="placeholder col-12"></span><br>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>