<template>
    <div class="wrapper-main-mb">
        <div class="main-wrapper">
            <br><br>
            <div v-if="querySearch.length === 0">
                No hay resultados
            </div>
            <div class="row row--15" v-else>
                <ul>
                    <div class="header-action">
                        <div class="row">
                            <div class="col-12">
                                <div class="axil-shop-top mb--40">
                                    <div class="align-items-center justify-content-lg-end justify-content-between">
                                        <br>
                                        <span class="filter-results">Mostrando {{ querySearch.length }} resultados en tu
                                            busqueda: "<b>{{ searchedProduct.searchQuery }}"</b></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-list-group-item v-for="(prod, index) in paginador(querySearch)" :key="index"
                        class="col-xl-4 col-sm-6">
                        <div class="axil-product product-style-one">
                            <div class="thumbnail">
                                <router-link :to="{ name: 'Show', params: { 'id': prod.simple_fields.url_key } }">
                                    <div v-for="(img, ix) in prod.images.slice(0, 1)" :key="ix">
                                        <img data-sal-delay="200" data-sal-duration="800" loading="lazy" class="main-img"
                                            :src="img.path"
                                            alt="Product Images" style="width: 40%;">
                                    </div>
                                </router-link>
                                <div class="label-block label-right" v-for="(product, ws) in wishlist" :key="ws"
                                    style="right:15px">
                                    <div class="product-badget" v-if="product.product_id === prod.id"
                                        style="background: #ff0000;z-index: 100;position: relative;">
                                        <a @click.prevent="removeItem(prod.id)">
                                            <i class="far fa-heart"></i>
                                        </a>
                                    </div>
                                    <div class="product-badget" v-else>
                                        <a @click.prevent="addWishlist(prod.id)">
                                            <i class="far fa-heart"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="label-block label-right" v-if="wishlist.length === 0">
                                    <div class="product-badget">
                                        <a @click.prevent="addWishlist(prod.id)">
                                            <i class="far fa-heart"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="label-block label-right" v-if="prod.inventory.mb_special_price"
                                    style="margin-top: 15%;right:15px">
                                    <div class="product-badget" style="background: #38d10b;">OFERTA</div>
                                </div>
                                <div class="label-block label-left" style="left:10px">
                                    <div class="product-badget" v-if="prod.simple_fields.refurbished === 1"
                                        style="background: #000;">
                                        REACONDICIONADO</div>
                                </div>
                            </div>
                            <div class="product-content">
                                <div class="inner">
                                    <div class="product-rating">
                                        <div class="star-rating">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="far fa-star"></i>
                                        </div>
                                    </div>
                                    <h5 class="title">
                                        <router-link :to="{ name: 'Show', params: { 'id': prod.simple_fields.url_key } }">
                                            <div
                                                style="overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-align: center;padding:0px 30px 0px;">
                                                {{ prod.simple_fields.name }}
                                            </div>
                                        </router-link>
                                    </h5><br>
                                    <div class="product-price-variant">
                                        <div v-if="prod.inventory.mb_special_price">
                                            <span class="price current-price" style="font-size: 18px;">
                                                {{ $filters.money(prod.inventory.mb_special_price) }}
                                            </span><br>
                                            <span class="price old-price" style="font-size: 18px;">
                                                {{ $filters.money(prod.inventory.mb_price) }}
                                            </span><br><br>
                                        </div>
                                        <div v-else style="margin-bottom: 45px;">
                                            <span class="price current-price" style="font-size: 18px;">
                                                {{ $filters.money(prod.inventory.mb_price) }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-list-group-item>
                </ul>
                <!-- End Single Product  -->
            </div>
            <b-pagination align="center" @click="scrollBehavior()" :total-rows="querySearch.length" v-model="paginaActual" :per-page="itemsPorPagina"
                size="lg">
            </b-pagination>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { useToast } from "vue-toastification";
import store from '@/store'

export default {
    name: 'SearchResults',
    data() {
        return {
            toast: useToast(),
            wishlist: [],
            paginaActual: 1,
            itemsPorPagina: 10
        }
    },
    computed: {
        ...mapState(['querySearch', 'searchedProduct'])
    },
    mounted() {
        // this.getWishlist();
        this.scrollBehavior();
    },
    methods: {
        paginador(items) {
            const indiceInicio = (this.paginaActual - 1) * this.itemsPorPagina;
            const indiceFinal =
                indiceInicio + this.itemsPorPagina > items.length
                    ? items.length
                    : indiceInicio + this.itemsPorPagina;
            return items.slice(indiceInicio, indiceFinal);
        },
        scrollBehavior() { 
            window.scrollTo(0, 0)
        },
        addWishlist(id_product) {
            if (store.state.user.token) {
                store.dispatch("addProductToWishlist", {
                    productId: id_product
                })
                    .then(() => {
                        this.getWishlist();
                        this.toast.success('Artículo añadido a favoritos')
                    }).catch((err) => {
                        console.log(err);
                    });
            } else {
                this.toast.error('Inicia sesión para poder realizar esta acción')
            }
        },
        removeItem(id_product) {
            store.dispatch('removeProductFromWishlist', {
                productId: id_product,
            })
                .then(() => {
                    this.toast.success("Artículo eliminado de la lista de deseos")
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.wishlist.pop()
                    this.deletedProduct();
                });
        },
        getWishlist() {
            if (store.state.user.token) {
                store.dispatch('getWishlist')
                    .then(response => {
                        response.data.wishlist.forEach((product) => {
                            this.wishlist.push(product)
                        })
                    })
                    .catch(error => {
                        console.error('Error fetching getWishlist:', error);
                    });
            }
        },
        deletedProduct() {
            this.wishlist = store.state.user.wishlist
        }
    }
}
</script>
<style>
.page-link.active,
.active>.page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: #508dcb;
    border-color: #508dcb;
}

.page-link {
    position: relative;
    display: block;
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    font-size: var(--bs-pagination-font-size);
    color: #212529;
    text-decoration: none;
    background-color: var(--bs-pagination-bg);
    border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.page-link.disabled,
.disabled>.page-link {
    color: #0dcaf0;
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:last-child .page-link {
    border-top-right-radius: var(--bs-pagination-border-radius);
    border-bottom-right-radius: var(--bs-pagination-border-radius);
    background: #ff000000;
    color: #ff000000;
    border: #ff000000;
    pointer-events: none;
}

.page-item:first-child .page-link {
    border-top-left-radius: var(--bs-pagination-border-radius);
    border-bottom-left-radius: var(--bs-pagination-border-radius);
    background: #ff000000;
    color: #ff000000;
    border: #ff000000;
    pointer-events: none;
}</style>