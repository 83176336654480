<template>
    <div>
        <div class="card-order c1">
            <div class="parent">
                <div class="child">
                    <div class="left">
                        <div class="icon-profile" style="width: 80px;">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12" style="height: 80px;"></span>
                            </p>
                        </div>
                        <div class="info">
                            <div style="width: 150px;">
                                <p class="card-text placeholder-glow">
                                    <span class="placeholder col-12"></span><br>
                                    <span class="placeholder col-12"></span>
                                </p>
                            </div><br>
                            <h3 class="data text-dark">
                                <p class="card-text placeholder-glow">
                                    <span class="placeholder col-6"></span>
                                </p>
                            </h3>
                            <div><br>
                                <p class="card-text placeholder-glow" style="text-align: left;">
                                    <span class="placeholder col-8"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-order c1">
            <div class="parent">
                <div class="child">
                    <div class="left">
                        <div class="icon-profile" style="width: 80px;">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12" style="height: 80px;"></span>
                            </p>
                        </div>
                        <div class="info">
                            <div style="width: 150px;">
                                <p class="card-text placeholder-glow">
                                    <span class="placeholder col-12"></span><br>
                                    <span class="placeholder col-12"></span>
                                </p>
                            </div><br>
                            <h3 class="data text-dark">
                                <p class="card-text placeholder-glow">
                                    <span class="placeholder col-6"></span>
                                </p>
                            </h3>
                            <div><br>
                                <p class="card-text placeholder-glow" style="text-align: left;">
                                    <span class="placeholder col-8"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-order c1">
            <div class="parent">
                <div class="child">
                    <div class="left">
                        <div class="icon-profile" style="width: 80px;">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12" style="height: 80px;"></span>
                            </p>
                        </div>
                        <div class="info">
                            <div style="width: 150px;">
                                <p class="card-text placeholder-glow">
                                    <span class="placeholder col-12"></span><br>
                                    <span class="placeholder col-12"></span>
                                </p>
                            </div><br>
                            <h3 class="data text-dark">
                                <p class="card-text placeholder-glow">
                                    <span class="placeholder col-6"></span>
                                </p>
                            </h3>
                            <div><br>
                                <p class="card-text placeholder-glow" style="text-align: left;">
                                    <span class="placeholder col-8"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-order c1">
            <div class="parent">
                <div class="child">
                    <div class="left">
                        <div class="icon-profile" style="width: 80px;">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12" style="height: 80px;"></span>
                            </p>
                        </div>
                        <div class="info">
                            <div style="width: 150px;">
                                <p class="card-text placeholder-glow">
                                    <span class="placeholder col-12"></span><br>
                                    <span class="placeholder col-12"></span>
                                </p>
                            </div><br>
                            <h3 class="data text-dark">
                                <p class="card-text placeholder-glow">
                                    <span class="placeholder col-6"></span>
                                </p>
                            </h3>
                            <div><br>
                                <p class="card-text placeholder-glow" style="text-align: left;">
                                    <span class="placeholder col-8"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-order c1">
            <div class="parent">
                <div class="child">
                    <div class="left">
                        <div class="icon-profile" style="width: 80px;">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12" style="height: 80px;"></span>
                            </p>
                        </div>
                        <div class="info">
                            <div style="width: 150px;">
                                <p class="card-text placeholder-glow">
                                    <span class="placeholder col-12"></span><br>
                                    <span class="placeholder col-12"></span>
                                </p>
                            </div><br>
                            <h3 class="data text-dark">
                                <p class="card-text placeholder-glow">
                                    <span class="placeholder col-6"></span>
                                </p>
                            </h3>
                            <div><br>
                                <p class="card-text placeholder-glow" style="text-align: left;">
                                    <span class="placeholder col-8"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>