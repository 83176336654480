 <template>
    <div class="slick-single-layout"  style="padding: 0 13%;">
        <div class="axil-product product-style-two border-product-slide">
            <div class="">
            <div class="card-body">
                <h5 class="card-title placeholder-glow" style="margin-top: 5%;">
                    <span class="placeholder col-6" style="height: 80px;"></span>
                </h5>
                <p class="card-text placeholder-glow">
                    <span class="placeholder col-6"></span><br>
                    <span class="placeholder col-6"></span><br>
                    <span class="placeholder col-8"></span>
                </p>
                </div>
            </div>
        </div>
    </div>
</template>