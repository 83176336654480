<template>
    <div>
        <div div class="card-order c1">
            <div class="parent">
                <div class="child">
                    <div class="left">
                        <div class="icon-profile">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12" style="height: 50px;"></span>
                            </p>
                        </div>
                        <div class="info" style="width: 150px;">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12"></span><br>
                                <span class="placeholder col-12"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="child">
                    <div class="left">
                        <div class="icon-profile">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12" style="height: 50px;"></span>
                            </p>
                        </div>
                        <div class="info" style="width: 150px;">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12"></span><br>
                                <span class="placeholder col-12"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="child">
                    <div class="left">
                        <div class="icon-profile">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12" style="height: 50px;"></span>
                            </p>
                        </div>
                        <div class="info" style="width: 150px;">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12"></span><br>
                                <span class="placeholder col-12"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="child">
                    <div class="left">
                        <div class="icon-profile">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12" style="height: 50px;"></span>
                            </p>
                        </div>
                        <div class="info" style="width: 150px;">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12"></span><br>
                                <span class="placeholder col-12"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="child">
                    <div class="left">
                        <div class="icon-profile">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12" style="height: 50px;"></span>
                            </p>
                        </div>
                        <div class="info" style="width: 150px;">
                            <p class="card-text placeholder-glow">
                                <span class="placeholder col-12"></span><br>
                                <span class="placeholder col-12"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>