<template>
    <div>
        <div class="card-order c1 color-order">
            <div class="row">
                <div class="col-8 order-date">
                    <p class="card-text placeholder-glow">
                        <span class="placeholder col-12"></span>
                    </p>
                </div>
                <div class="col-4 order-status">
                    <p class="card-text placeholder-glow">
                        <span class="placeholder col-4"></span>
                    </p>
                </div>
            </div><br>
            <div class="row">
                <div class="col-7">
                    <p class="card-text placeholder-glow">
                        <span class="placeholder col-12"></span>
                    </p>
                </div>
                <div class="col-5">
                    <p class="card-text placeholder-glow">
                        <span class="placeholder col-12"></span>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <p class="card-text placeholder-glow" style="text-align: left;">
                        <span class="placeholder col-6"></span>
                    </p>
                </div>
                <div class="col-5">
                    <p class="card-text placeholder-glow">
                        <span class="placeholder col-12"></span>
                    </p>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-7">
                    <p class="card-text placeholder-glow" style="text-align: left;">
                        <span class="placeholder col-4"></span>
                    </p>
                </div>
                <div class="col-5">
                    <p class="card-text placeholder-glow">
                        <span class="placeholder col-12"></span>
                    </p>
                </div>
            </div>
            <br><br>
            <p class="card-text placeholder-glow" style="text-align: left;">
                <span class="placeholder col-4"></span>
            </p>
            <div class="child">
                <div class="left">
                    <div class="icon-profile">
                        <p class="card-text placeholder-glow">
                            <span class="placeholder col-12" style="height: 50px;"></span>
                        </p>
                    </div>
                    <div class="info" style="width: 150px;">
                        <p class="card-text placeholder-glow" style="text-align: left;">
                            <span class="placeholder col-12"></span><br>
                            <span class="placeholder col-12"></span>
                        </p>
                        <p class="card-text placeholder-glow" style="text-align: left;">
                            <span class="placeholder col-6"></span><br>
                        </p>
                    </div>
                </div>
            </div>
            <br><br>
            <p class="card-text placeholder-glow" style="text-align: left;">
                <span class="placeholder col-4"></span>
            </p>
            <div class="row">
                <div class="col-3">
                    <p class="card-text placeholder-glow">
                        <span class="placeholder col-12" style="height: 50px;"></span>
                    </p>
                </div>
                <div class="col-9">
                    <p class="card-text placeholder-glow">
                        <span class="placeholder col-12"></span><br>
                        <span class="placeholder col-12"></span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>