<template>
    <!-- Header Search Modal End -->
    <div class="header-search-modal" id="header-search-modal" :class="openSearch ? 'open' : ''">
        <div class="row">
            <!--div class="col-6" style="width: 25%;position: absolute;text-align: left;">
                <img src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/logo_footer.png"
                    alt="Product Images">
            </div-->
            <div class="col-6">
                <button @click="closeDialog()" class="card-close sidebar-close">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
        <div class="header-search-wrap">
            <div class="card-header">
                <div class="row" style="width: 23%;text-align: left;margin-left: 10px;margin-top: -60px;">
                    <img src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/logo_footer.png"
                        alt="Product Images">
                </div>
                <form action="#">
                    <div class="input-group">
                        <input type="search" class="form-control" v-model="product" name="prodSearch" id="prodSearch"
                            placeholder="Buscar en MainbitShop...">
                    </div>
                </form>
                <b-button @click.prevent="searchProducts()" style="background: #6296cb;color: #fff;"
                    :disabled="!isDisabled">Buscar</b-button>
            </div>
            <div class="card-body">
                <div class="search-result-header">
                    <b-spinner v-if="isLoading" variant="info" class="position-loader" type="grow"
                        label="Loading..."></b-spinner>
                    <h6 class="title" v-if="productEntry.length != 0">{{ products.length }} resultados en tu busqueda: <br>
                        <b>"{{ product }}"</b>
                    </h6>
                    <h6 class="title">{{ result }}</h6>
                    <router-link :to="{ name: 'Search Results' }" class="view-all" v-if="productEntry.length != 0"
                        @click="closeDialog()">Ver todo</router-link>
                </div>
                <div class="psearch-results">
                    <div class="axil-product-list" v-for="(product, index) in productEntry" :key="index">
                        <div class="thumbnail">
                            <router-link :to="{ name: 'Show', params: { 'id': product.simple_fields.url_key } }">
                                <div v-for="(img, ix) in product.images.slice(0, 1)" :key="ix">
                                    <img :src="img.path"
                                        alt="Product Images">
                                </div>
                            </router-link>
                        </div>
                        <div class="product-content">
                            <div class="product-rating">
                                <span class="rating-icon">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fal fa-star"></i>
                                </span>
                            </div>
                            <h6 class="product-title"
                                style="overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
                                <router-link :to="{ name: 'Show', params: { 'id': product.simple_fields.url_key } }">{{ product.simple_fields.name }}</router-link>
                            </h6>
                            <div class="product-price-variant">
                                <div v-if="product.inventory.mb_special_price">
                                    <span class="price current-price" style="font-size: 15px;">
                                        {{ $filters.money(product.inventory.mb_special_price) }}
                                    </span><br>
                                    <span class="price old-price" style="font-size: 15px;">
                                        {{ $filters.money(product.inventory.mb_price) }}
                                    </span><br><br>
                                </div>
                                <div v-else>
                                    <span class="price current-price" style="font-size: 15px;">
                                        {{ $filters.money(product.inventory.mb_price) }}
                                    </span>
                                </div>
                            </div>
                            <div class="product-cart">
                                <a class="cart-btn" @click.prevent="add(product.simple_fields.id)">
                                    <i class="fal fa-shopping-cart"></i>
                                </a>
                                <span v-for="(prod, ws) in wishlist" :key="ws" style="position: absolute;">
                                    <a v-if="prod.product_id === product.simple_fields.id" class="cart-btn"
                                        @click.prevent="removeItem(product.simple_fields.id)"
                                        style="background: #ff0000;color: #fff;z-index: 100;position: relative;">
                                        <i class="fal fa-heart"></i>
                                    </a>
                                    <a class="cart-btn" v-else @click.prevent="addWishlist(product.simple_fields.id)">
                                        <i class="far fa-heart"></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="search-result-header" style="text-align: center;">
                    <router-link :to="{ name: 'Search Results' }" class="view-all" v-if="productEntry.length != 0"
                        @click="closeDialog()">Ver todo</router-link>
                </div>
                <div class="psearch-results" v-if="featuredProducts.length != 0">
                    <div class="axil-product-list" v-for="(product, index) in featuredProducts" :key="index">
                        <div class="thumbnail" style="width: 40%;">
                            <router-link :to="{ name: 'Show', params: { 'id': product.simple_fields.url_key } }">
                                <div v-for="(img, ix) in product.images.slice(0, 1)" :key="ix">
                                    <img :src="img.path"
                                        alt="Product Images">
                                </div>
                            </router-link>
                        </div>
                        <div class="product-content">
                            <div class="product-rating">
                                <span class="rating-icon">
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fas fa-star"></i>
                                    <i class="fal fa-star"></i>
                                </span>
                            </div>
                            <h6 class="product-title"
                                style="overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
                                <a href="single-product.html">{{ product.simple_fields.name }}</a>
                            </h6>
                            <div class="product-price-variant">
                                <div v-if="product.inventory.mb_special_price && product.inventory.mb_special_price_to >= currentDate">
                                    <span class="price current-price" style="font-size: 15px;">
                                        {{ $filters.money(product.inventory.mb_special_price) }}
                                    </span><br>
                                    <span class="price old-price" style="font-size: 15px;">
                                        {{ $filters.money(product.inventory.mb_price) }}
                                    </span><br><br>
                                </div>
                                <div v-else>
                                    <span class="price current-price" style="font-size: 15px;">
                                        {{ $filters.money(product.inventory.mb_price) }}
                                    </span>
                                </div>
                            </div>
                            <div class="product-cart" style="margin-top: -20px;">
                                <a class="cart-btn" @click.prevent="add(product.simple_fields.id)">
                                    <i class="fal fa-shopping-cart"></i>
                                </a>
                                <span v-for="(prod, ws) in wishlist" :key="ws" style="position: absolute;">
                                    <a v-if="prod.product_id === product.simple_fields.id" class="cart-btn"
                                        @click.prevent="removeItem(product.simple_fields.id)"
                                        style="background: #ff0000;color: #fff;z-index: 100;position: relative;">
                                        <i class="fal fa-heart"></i>
                                    </a>
                                    <a class="cart-btn" v-else @click.prevent="addWishlist(product.simple_fields.id)">
                                        <i class="far fa-heart"></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Header Search Modal End -->
</template>
<script>
import { useToast } from "vue-toastification";
import store from '@/store'
import { mapState } from 'vuex'

export default {
    name: "SearchDropdown",
    props: {
        openSearch: {
            type: Boolean,
        },
    },
    data() {
        return {
            products: [],
            product: '',
            productEntry: [],
            quantity: 1,
            toast: useToast(),
            wishlist: [],
            result: ' ',
            featuredProducts: [],
            currentDate: this.formatDate(new Date())
        }
    },
    mounted() {
        this.searchProducts();
        if (store.state.user.token) {
            // this.getWishlist();
        }
    },
    computed: {
        ...mapState(['isLoading', 'querySearch']),
        isDisabled() {
            return this.product && this.product.length > 3
        },
    },
    methods: {
        closeDialog() {
            this.$emit('changeSearch')
            this.productEntry = []
            this.product = null
        },
        getFeaturedProducts() {
            store.dispatch('featuredProducts')
                .then(response => {
                    this.featuredProducts = response.data.products
                })
                .catch(error => {
                    console.error('Error fetching featuredProducts:', error);
                });
        },
        searchProducts() {
            if (this.product) {
                store.dispatch("searchProduct", {
                    searchQuery: this.product
                }).then((response) => {
                    this.productEntry = response.data.products.slice(this.productEntry.length - 5);
                    this.products = response.data.products
                    this.result = null
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    if (this.productEntry.length === 0) {
                        this.result = 'No hay resultados para: ' + this.product + '. Te recomendamos nuestra sección de productos destacados.'
                        this.getFeaturedProducts();
                    }
                });
            }
        },
        add(id) {
            if (store.state.user.token) {
                store.dispatch("addProductToCart", {
                    quantity: this.quantity,
                    productId: id
                })
                    .then(() => {
                        store.dispatch("getCart")
                        this.toast.success('Articulo añadido al carrito')

                    }).catch((err) => {
                        console.log(err);
                    });
            } else {
                this.toast.error('Inicia sesion para poder realizar esta accion')
            }
        },
        addWishlist(id) {
            if (store.state.user.token) {
                store.dispatch("addProductToWishlist", {
                    productId: id
                })
                    .then(() => {
                        this.getWishlist();
                        this.toast.success('Artículo añadido a favoritos')
                    }).catch((err) => {
                        console.log(err);
                    });
            } else {
                this.toast.error('Inicia sesión para poder realizar esta acción')
            }
        },
        removeItem(id) {
            store.dispatch('removeProductFromWishlist', {
                productId: id,
            })
                .then(() => {
                    this.toast.success("Artículo eliminado de la lista de deseos")
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.wishlist.pop()
                });
        },
        getWishlist() {
            if (store.state.user.token) {
                store.dispatch('getWishlist')
                    .then(response => {
                        response.data.wishlist.forEach((product) => {
                            this.wishlist.push(product)
                        })
                    })
                    .catch(error => {
                        console.error('Error fetching getWishlist:', error);
                    });
            }
        },
        formatDate(date) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
            const year = date.getFullYear();
            return `${year}-${month}-${day}`;
        }
    }
}
</script>