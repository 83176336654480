<template>
    <div class="row">
        <div class="col-lg-12 offset-xl-4">
            <div class="axil-signin-form-wrap">
                <div>
                    <h1 class="text-center">Bienvenid@ a MainbitShop</h1><br>
                    <p>Ahora es tiempo de verificar tu cuenta, <br>te hemos envíado un correo a tu email.</p><br>
                    <p><b>¿No has recibido el correo de verificación?</b></p>
                    <div class="form-group">
                        <input type="email" class="form-control" name="email" v-model="email" placeholder="yo@example.com" >
                        <BButton v-if="email.length > 0 && validEmail()" @click.prevent="verification()" variant="info" :disabled="isDisable">Reenvíar</BButton>
                    </div>
                    <br><br><br>
                    <p>Si verificaste tu cuenta</p>
                    <router-link :to="{name: 'Sign In'}">
                        <BButton variant="danger">Comienza aquí</BButton>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from '@/store'
import { useToast } from "vue-toastification";
    export default {
        name: 'WelcomeView',
        components: {
        },
        data: () => ({
            toast: useToast(),
            email: [],
            isDisable: false
        }),
        methods: {
            verification(){
                store.dispatch('verificationEmail', {email: this.email})
                .then((response) => {
                    console.log("email resend", response)
                    console.log("email", this.email)
                    let status = typeof response
                    if (status === "undefined"){
                        this.toast.success("Correo envíado correctamente")
                        this.disabled();
                        this.$router.push({name: 'Welcome'})
                    }
                    else{
                        if (!this.validEmail(this.email)) {
                            this.toast.error('Introduce una dirección de correo válida.')
                            this.disabled();
                            this.$router.push({name: 'Welcome'})
                        }
                        else if (response.status === 400){
                            this.toast.success("Tu cuenta ya ha sido verificada, inicia sesión")
                            this.disabled();
                            this.$router.push({name: 'Sign In'})
                        }
                        else if (response.status === 500){
                            this.toast.error("Ha ocurrido un error, intentalo más tarde")
                            this.disabled();
                            this.$router.push({name: 'Welcome'})
                        }
                    }
                    
                }).catch((err) => {
                    console.log(err);
                });
            },
            disabled(){
                this.isDisable = true;
                setTimeout(() => this.isDisable = false, 50000);
            },
            validEmail() {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(this.email);
            }
        },
        mounted(){
        }
    }
</script>
<style>
    .btn-danger {
        --bs-btn-color: #fff;
        --bs-btn-bg: #e72043;
        --bs-btn-border-color: #dc3545;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #bb2d3b;
        --bs-btn-hover-border-color: #b02a37;
        --bs-btn-focus-shadow-rgb: 225, 83, 97;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #b02a37;
        --bs-btn-active-border-color: #a52834;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #fff;
        --bs-btn-disabled-bg: #dc3545;
        --bs-btn-disabled-border-color: #dc3545;
        width: 55%;
        height: 8%;
        margin-left: 0;
        margin-top: 10px;
        border-radius: 6px;
        font-size: var(--font-size-b1);
        font-weight: 700;
        display: inline-block;
        position: relative;
        transition: all 0.3s ease-in-out;
        z-index: 1; 
    }
    .btn-info {
        --bs-btn-color: #fff;
        --bs-btn-bg: #6296cb;
        --bs-btn-border-color: #6296cb;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #6296cb;
        --bs-btn-hover-border-color: #6296cb;
        --bs-btn-focus-shadow-rgb: 225, 83, 97;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #6296cb;
        --bs-btn-active-border-color: #6296cb;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #fff;
        --bs-btn-disabled-bg: #6296cb;
        --bs-btn-disabled-border-color: #6296cb;
        width: 55%;
        height: 8%;
        margin-left: 0;
        margin-top: 10px;
        border-radius: 6px;
        font-size: var(--font-size-b1);
        font-weight: 700;
        display: inline-block;
        position: relative;
        transition: all 0.3s ease-in-out;
        z-index: 1; 
    }
</style>


