<template>
    <div class="sticky-header newsletter-popup-modal">
        <NotFound></NotFound>
    </div>
</template>

<script>
    import NotFound from '../components/NotFoundComponent.vue'

    export default {
        name: 'IndexView',
        components: {
            NotFound
        }
    }
</script>