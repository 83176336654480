// utils/jwt.js
export function decodeJWT(token) {
    if (!token) return null;
  
    const parts = token.split('.');
    if (parts.length !== 3) return null;
  
    try {
      const decoded = atob(parts[1]); // Decodifica la parte del payload
      return JSON.parse(decoded);
    } catch (e) {
      return null;
    }
  }
  
  export function isTokenExpired(token) {
    const decoded = decodeJWT(token);
    if (!decoded || !decoded.exp) return true; // Si no se puede decodificar o no tiene exp, considera que ha expirado
  
    const exp = decoded.exp * 1000; // La expiración en segundos a milisegundos
    const now = Date.now();
    
    return now >= exp; // Devuelve true si el token ha expirado
  }
  