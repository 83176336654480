<template>
  <div>
    <p v-if="isLoading">Redirecting...</p>
    <router-link id="myLink" :to="{ name: 'Profile' }" class="logo logo-light">profile</router-link>
  </div>
</template>

<script setup>
import { useHandleSignInCallback } from '@logto/vue';
import router from "@/router";

const { isLoading } = useHandleSignInCallback(() => {
  router.push({ name: 'Profile' });
});
</script>


<style scoped>

</style>