<template>
    <div class="sticky-header newsletter-popup-modal">
        <div style="margin-top: 5%;">
            <img src="@/assets/images/logo/logo.jpeg" alt="Site Logo">
        </div>
        <div class="col-lg-6 text-center"><br><br>
            <span class="title-highlighter highlighter-secondary text-center">
                <i class="fal fa-exclamation-circle text-center"></i> ¡Ups! Falta algo.
            </span><br><br>
            <h1 class="text-center">Ponte en contacto con nuestro equipo de soporte.</h1><br>
        </div><br>
        <button style="width: 30%;" @click="handleLogOut()" class="axil-btn btn-bg-secondary right-icon text-left">Volver al inicio</button>
        <div class="col-lg-6 contenedor">
            <img src="../assets/images/others/error_page.png" alt="404" style="width: 75%;margin-top: -90px;">
        </div>
    </div>
</template>
<style>
   .contenedor{
        position: relative;
        display: inline-block;
        text-align: center;
    }
    .centrado{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        font-size: 190px;
        color: #00bfff87;
    }
</style>
<script>
import { useLogto } from '@logto/vue';

export default {
        el: '#app',
        name: 'LogoToView',
        data: () => ({
            userDataFromLogTo: null,
        }),
        methods: {
            async getUserDataFromLogTo() {
                sessionStorage.removeItem('TOKEN');
                sessionStorage.removeItem('REFRESH_TOKEN');
                this.logto.clearAccessToken();
                this.logto.clearAllTokens();
            },
            handleLogOut() {
                this.logto.signOut('https://mobile.mainbitpreproduccion.shop/');
            },
        },
        created() {
          this.logto = useLogto();
        },
        mounted() {
            this.getUserDataFromLogTo();
        }
}
</script>