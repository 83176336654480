<template>
     <b-button :disabled="product.inventory.qty === 0" class="axil-btn btn-bg-primary">
          <slot></slot>
     </b-button>
</template>
<script>
     export default {
          props: {
               product: {
                    required: true
               }
          }
     }
</script>