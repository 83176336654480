<template>
    <div class="wrapper-main-mb">
        <!-- Start Breadcrumb Area  -->
        <div class="axil-breadcrumb-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-8">
                        <div class="inner">
                            <ul class="axil-breadcrumb">
                                <li class="axil-breadcrumb-item"><router-link :to="{ name: 'Profile' }">MI CUENTA</router-link>
                                </li>
                                <li class="separator"></li>
                                <li class="axil-breadcrumb-item active" aria-current="page">RESEÑAS</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="tab-pane" id="nav-address" role="tabpanel" style="padding: 0 20px;">
            <div class="axil-dashboard-address">
                <div v-if="isLoading">
                    <PlaceHolder></PlaceHolder>
                </div>
                <div v-else class="card-order c1" v-for="(review, rw) in reviews" :key="rw">
                    <div class="parent">
                        <div class="child">
                            <div class="left">
                                <div class="icon-profile">
                                    <i class="far fa-edit" style="font-size: 20px;color: #0a53be;"></i>
                                </div>
                                <div class="info">
                                        <div style="width: 150px;">
                                            <h3 class="data name-order text-dark"><b>{{ review.title }}</b></h3>
                                        </div><br>
                                        <h3 class="data text-dark">
                                            {{ review.comment }}
                                        </h3>
                                </div>
                            </div>
                            <div style="margin-right: 1px;">
                                <b-button v-b-modal.modal-1 @click.prevent="deleteData(review.id)">
                                    <i class="far fa-trash"></i>
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info" v-if="reviews.length === 0">
                    <h3 class="data name-order text-dark text-center">No hay reseñas aún</h3>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
import store from "@/store";
import { useRouter } from "vue-router";
import { mapState } from "vuex";
import PlaceHolder from '@/components/PlaceHolder/WishList.vue'
import { useToast } from "vue-toastification";

export default {
    data: () => ({
        store: useStore(),
        router: useRouter(),
        user: {},
        reviews: [],
        toast: useToast(),
    }),
    components:{
        PlaceHolder
    },
    created() {
        if (store.state.user.token) {
            this.getUserData()
        } else {
            this.user = computed(() => '');
            if (store.state.user.tokenFresh) {
                store.dispatch("refreshToken")
                    .then((res) => {
                        console.log(res);
                    }).catch((err) => {
                        console.log(err);
                    });
            }
        }
    },
    computed:{
        ...mapState(['isLoading'])
    },
    methods: {
        getUserData() {
            store.dispatch("getUser").then(() => {
                this.reviews = this.store.state.user.data.reviews;
                if (this.store.state.user.token) {
                    this.user = computed(() => store.state.user.data);

                } else {
                    this.user = computed(() => '');
                }
            })
                .catch((err) => {
                    console.log(err)
                })
        },
        deleteData(id) {
            store.dispatch('deleteReview', id)
            .then(() => {
                console.log("review",  store.dispatch('deleteReview'))
                    this.toast.success("Reseña eliminada.")
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.reviews.pop();
                });
        },
    }
}
</script>
<style>
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #164170;
    --bs-btn-border-color: none;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: none;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: none;
}
.boton-heart {
    background: transparent;
    border:none;
    color: #e51c21;
    font-size: 30px;
}
</style>