<template>
    <div class="wrapper-main-mb">
        <main class="main-wrapper">
            <!-- Start Breadcrumb Area  -->
            <div class="axil-breadcrumb-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12 col-md-8">
                            <div class="inner">
                                <ul class="axil-breadcrumb">
                                    <li class="axil-breadcrumb-item"><router-link :to="{ name: 'Index' }">INICIO</router-link>
                                    </li>
                                    <li class="separator"></li>
                                    <li class="axil-breadcrumb-item active" aria-current="page">Mi cuenta</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Breadcrumb Area  -->

            <!-- Start My Account Area  -->
            <div class="axil-dashboard-area axil-section-gap">
                <div class="container">
                    <div class="axil-dashboard-warp">
                        <div class="axil-dashboard-author">
                            <div class="media">
                                <div class="media-body">
                                    <div class="tab-pane fade show active" id="nav-dashboard" role="tabpanel">
                                        <div class="axil-dashboard-overview">
                                            <div class="row">
                                                <div class="col-6 text-right" v-if="user.image == NULL">
                                                    <img  class="img-circle" src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/logo_footer.png" alt="Profile">
                                                </div>
                                                <div class="col-6 text-right" v-else>
                                                    <img  class="img-circle" :src="'https://rc1.mainbitpreproduccion.shop/storage/'+user.image" alt="Profile">
                                                </div>
                                                <div class="col-6 text-left">
                                                    <div class="title-welcome">
                                                        <b>Hola, {{ user.first_name }}!</b><br>
                                                        <!--p v-if="rewards.total_reward_points == NULL">Tienes : 0 puntos.</p>
                                                        <p v-else>Tienes : {{ rewards.total_reward_points }} puntos.</p-->
                                                        <p style="color: #6497cb;font-size: 14px;">Editar información &nbsp;<i class="far fa-edit"></i></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <splide :options="options">
                                                <splide-slide>
                                                    <div v-if="isLoading">
                                                        <PlaceHolder></PlaceHolder>
                                                    </div>
                                                    <div v-else class="card-order c1">
                                                        <h3 class="heading">Pedidos</h3>
                                                        <div class="parent">
                                                            <span v-if="orders.length === 0">
                                                                No hay pedidos aún
                                                            </span>
                                                            <div class="child" v-for="(order, odr) in orders" :key="odr">
                                                                <div class="left">
                                                                    <div class="icon-profile" style="font-size: 30px;">
                                                                        📦
                                                                    </div>
                                                                    <router-link :to="{ name: 'Order Detail', params: { 'id': order.id } }" class="text-dark router-link">
                                                                        <div class="info">
                                                                            <h5 class="name">#{{ order.id }} | {{ $filters.inDate(order.created_at) }}</h5>
                                                                            <p class="cat" style="color:#6396cb">{{ order.status }}</p>
                                                                        </div>
                                                                    </router-link>
                                                                </div>
                                                                <div class="right">{{ order.total_qty_ordered }}</div>
                                                            </div>
                                                            <div>
                                                                <router-link v-if="orders.length != 0" class="route-profile" :to="{name: 'Orders'}" >
                                                                    <b>Ver más</b>
                                                                </router-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </splide-slide>
                                                <splide-slide>
                                                    <div v-if="isLoading">
                                                        <PlaceHolder></PlaceHolder>
                                                    </div>
                                                    <div v-else class="card-order c1">
                                                        <h3 class="heading">Lista de deseos</h3>
                                                        <div class="parent">
                                                            <span v-if="wishList.length === 0">
                                                                No hay productos.
                                                            </span>
                                                            <div class="child" v-for="(wishlist, wish) in wishList" :key="wish">
                                                                <div class="left">
                                                                    <div class="icon-profile">
                                                                        <div v-for="(img, ix) in wishlist.product_details.images.slice(0, 1)" :key="ix">
                                                                            <router-link v-if="img.path" :to="{ name: 'Show', params: { 'id': wishlist.product_details.simple_fields.url_key } }">
                                                                                <img data-sal-delay="200" data-sal-duration="800" loading="lazy" class="main-img"
                                                                                :src="img.path"
                                                                                alt="Product Images">
                                                                            </router-link>
                                                                        </div>
                                                                    </div>
                                                                    <router-link :to="{ name: 'Show', params: { 'id': wishlist.product_details.simple_fields.url_key } }">
                                                                        <div class="info" style="width: 130px;">
                                                                            <h5 class="name name-order">{{ wishlist.product_details.simple_fields.name}}</h5>
                                                                            <!--p class="cat">$ {{ wishlist.price }}</p-->
                                                                        </div>
                                                                    </router-link>
                                                                </div>
                                                                <div style="width: 45%;">
                                                                        <b-button v-b-modal.modal-1 @click.prevent="removeItem(wishlist.product_details.id)">
                                                                            <i class="far fa-heart-broken"></i>
                                                                        </b-button>
                                                                        <b-modal id="modal-1">
                                                                            <p class="my-4">¿Eliminar de favoritos?</p>
                                                                        </b-modal>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <router-link v-if="wishList.length != 0" class="route-profile" :to="{name: 'WishList'}" >
                                                                    <b>Ver más</b>
                                                                </router-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </splide-slide>
                                                <splide-slide>
                                                    <div v-if="isLoading">
                                                        <PlaceHolder></PlaceHolder>
                                                    </div>
                                                    <div v-else class="card-order c1">
                                                        <h3 class="heading">Reseñas</h3>
                                                        <div class="parent">
                                                            <span v-if="reviews.length === 0">
                                                                No hay reseñas aún.
                                                            </span>
                                                            <div class="child" v-for="(review, rw) in reviews" :key="rw">
                                                                <div class="left">
                                                                    <div class="info">
                                                                        <h5 class="name"></h5>
                                                                        <p class="cat comment-product">{{ review.comment }}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="route-profile">{{ review.status }}</div>
                                                            </div>
                                                            <div>
                                                                <router-link  v-if="reviews.length != 0" class="route-profile" :to="{name: 'My Reviews'}" >
                                                                    <b>Ver más</b>
                                                                </router-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </splide-slide>
                                                <splide-slide>
                                                    <div v-if="isLoading">
                                                        <PlaceHolder></PlaceHolder>
                                                    </div>
                                                    <div v-else class="card-order c1">
                                                        <h3 class="heading">Direcciones</h3>
                                                        <div class="parent">
                                                            <span v-if="addresses.length === 0">
                                                                No hay direcciones guardadas.
                                                            </span>
                                                            <!--       child 1 -->
                                                            <div class="child" v-for="(address, adrs) in addresses" :key="adrs">
                                                                <div class="left">
                                                                    <div class="icon-profile" style="font-size: 30px;">
                                                                        📍
                                                                    </div>
                                                                    <div class="info">
                                                                        <h5 class="name">{{ address.address1 }}, {{ address.city }}</h5>
                                                                        <p class="cat">{{ address.phone }}</p>
                                                                    </div>
                                                                </div>
                                                                <div style="color: #6497cb;font-size: 18px;"><i class="far fa-edit"></i></div>
                                                            </div>
                                                            <div>
                                                                <router-link v-if="addresses.length != 0" class="route-profile" :to="{name: 'Addresses'}" >
                                                                    <b>Ver más</b>
                                                                </router-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </splide-slide>
                                            </splide>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import store from "@/store";
import {useRouter} from "vue-router";
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import PlaceHolder from '@/components/PlaceHolder/CardsAccount.vue'
import {mapState} from 'vuex'

export default {
    data: () => ({
        store: useStore(),
        router: useRouter(),
        user: {},
        options: {
          rewind : true,
          width  : 800,
          gap    : '1rem',
        },
        wishList: [],
        rewards: [],
        orders: [],
        addresses: [],
        reviews: []
    }),
    components: {
        Splide,
        SplideSlide,
        PlaceHolder
    },
    created() {
      if(store.state.user.token) {
        this.getUserData()
      }else {
        this.user = computed(() => '');
        if(store.state.user.tokenFresh){
          store.dispatch("refreshToken")
          .then((res) => {
            console.log(res);
          }).catch((err) => {
            console.log(err);
          });
        }
      }
    },
    computed:{
        ...mapState(['isLoading'])
    },
    methods: {
        getUserData() {
            store.dispatch("getUser").then(() => {
                this.orders = this.store.state.user.data.orders.slice(this.orders.length - 5);
                this.addresses = this.store.state.user.data.addresses.slice(this.addresses.length - 5);
                this.rewards = this.store.state.user.data.rewards;
                this.reviews = this.store.state.user.data.reviews.slice(this.reviews.length - 5);
                this.wishList = store.state.user.wishlist.slice(this.wishList.length - 5);
            if (this.store.state.user.token){
                this.user = computed(() => store.state.user.data);
                
            }else{
                this.user = computed(() => '');
            }
            })
            .catch( (err) => {
            console.log(err)
            })
        },
    }
}

</script>

<style>
    .splide__arrow svg {
        fill: #4d96de;
        height: 2.5em;
        transition: fill .2s linear;
        width: 2.5em;
    }

    .splide__arrow:hover:not(:disabled) svg {
        fill: #8fb9e4;
    }

    .splide__pagination__page.is-active {
        background: #649ad0;
        z-index: 1;
    }
    .splide {
        padding: 3em;
        text-align: center;
    }
    .boton-heart {
        background: transparent;
        border:none;
        color: #e51c21;
        font-size: 20px;
    }
    .modal-dialog {
        position: relative;
        width: auto;
        pointer-events: none;
        margin: 20%;
    }
    .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 1px 20px;
    }
    .my-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 0.3rem !important;
        text-align: center;
    }
    .modal-footer > * {
        margin: calc(var(--bs-modal-footer-gap) * 0.5);
        width: 70px;
    }
    .modal-footer {
        display: flex;
        flex-shrink: 0;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
        background-color: var(--bs-modal-footer-bg);
        border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
        border-bottom-right-radius: var(--bs-modal-inner-border-radius);
        border-bottom-left-radius: var(--bs-modal-inner-border-radius);
    }
    .modal-header {
        display: none;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        padding: var(--bs-modal-header-padding);
        border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
        border-top-left-radius: var(--bs-modal-inner-border-radius);
        border-top-right-radius: var(--bs-modal-inner-border-radius);
    }
</style>