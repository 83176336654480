<template>
    <div class="row">
        <div class="col-lg-12 offset-xl-4">
            <div class="axil-signin-form-wrap">
                <div style="text-align: left;">
                    <h1 class="text-left">Política de Cookies</h1>
                    <h5>Última actualización: Noviembre 2023.</h5><br>
                    <p class="question">¿Qué son las “cookies”?</p>
                    <p class="answer">Las cookies constituyen una herramienta empleada por los servidores Web para almacenar
                        y recuperar información acerca de sus visitantes.
                        No es más que un identificador único en forma de archivo de texto que algunos servidores remiten al
                        dispositivo del usuario,
                        para registrar información acerca de lo que hemos estado haciendo por sus páginas, permitiendo así
                        mejorar la calidad y seguridad de la página web.
                        Poseen una fecha de caducidad, que puede oscilar desde el tiempo que dure la sesión,
                        hasta una fecha futura especificada a partir de la cual dejan de ser operativas.</p>

                    <p class="question">Utilidad de las cookies en este Sitio Web</p>
                    <p class="answer">En este Sitio Web se utilizan cookies para facilitar la navegación del Portal, por
                        motivos de seguridad y para obtener una mayor eficacia y personalización de los servicios ofrecidos
                        a los Usuarios.</p>

                    <p class="question">Tipos de Cookies empleadas en este Sitio Web</p>
                    <p class="answer"><b>Cookies de Sesión: </b>Expiran cuando el usuario cierra el navegador.<br>
                        <b>Cookies Permanentes:</b> Expiran cuando se cumple el objetivo para el que sirven (por ejemplo, el
                        usuario se mantiene identificado en las webs) o bien, cuando se borran manualmente.
                    </p>

                    <p class="question">Administración de las cookies en los navegadores</p>
                    <p class="answer">Puede permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la
                        configuración de las opciones de su navegador de Internet, por mencionar algunas:</p>
                    <ol>
                        <li>Google Chrome</li>
                        <li>Mozilla Firefox</li>
                        <li>Internet Explorer</li>
                    </ol>
                    <p class="answer">Si rechaza la instalación de cookies podrá seguir usando el sitio web, aunque el uso
                        de algunos de sus servicios podrá ser limitado y por tanto su experiencia en nuestro sitio web
                        resultar menos satisfactoria.</p>

                    <p class="question">Desactivación o eliminación de cookies</p>
                    <p class="answer">En cualquier momento podrá ejercer su derecho de desactivación o eliminación de
                        cookies de este sitio web. Estas acciones se realizan de forma diferente en función del navegador
                        que esté usando.<br>
                        A continuación, le indicamos cómo acceder a una cookie determinada del navegador <b>Chrome.</b>
                        Nota: estos pasos pueden variar en función de la versión del navegador:<br>

                        Vaya a Configuración o Preferencias mediante el menú Archivo o bien pinchando el icono de
                        personalización que aparece arriba a la derecha.<br>
                        Verá diferentes secciones, pinche la opción Mostrar opciones avanzadas.<br>
                        Vaya a Privacidad, Configuración de contenido.<br>
                        Seleccione Todas las cookies y los datos de sitios.<br>
                        Aparecerá un listado con todas las cookies ordenadas por dominio. Para que le sea más fácil
                        encontrar las cookies de un determinado dominio introduzca parcial o totalmente la dirección en el
                        campo Buscar cookies.<br>
                        Tras realizar este filtro aparecerán en pantalla una o varias líneas con las cookies de la web
                        solicitada. Ahora sólo tiene que seleccionarla y pulsar la X para proceder a su eliminación.<br>
                        Para acceder a la configuración de cookies del navegador <b>Internet Explorer</b> siga estos pasos (pueden
                        variar en función de la versión del navegador):<br>

                        Vaya a Herramientas, Opciones de Internet.<br>
                        Haga click en Privacidad.<br>
                        Mueva el deslizador hasta ajustar el nivel de privacidad que desee.<br>
                        Para acceder a la configuración de cookies del navegador <b>Firefox</b> siga estos pasos (pueden variar en
                        función de la versión del navegador):<br>

                        Vaya a Opciones o Preferencias según su sistema operativo.<br>
                        Haga click en Privacidad.<br>
                        En Historial elija Usar una configuración personalizada para el historial.<br>
                        Ahora verá la opción Aceptar cookies, puede activarla o desactivarla según sus preferencias.<br>
                        Para acceder a la configuración de cookies del navegador Safari para OSX siga estos pasos (pueden
                        variar en función de la versión del navegador):<br>

                        Vaya a Preferencias, luego Privacidad.<br>
                        En este lugar verá la opción Bloquear cookies para que ajuste el tipo de bloqueo que desea realizar.
                        Para acceder a la configuración de cookies del navegador <b>Safari</b> para <b>IOS</b> siga estos pasos (pueden
                        variar en función de la versión del navegador):<br>

                        Vaya a Ajustes, luego Safari.<br>
                        Vaya a Privacidad y Seguridad, verá la opción Bloquear cookies para que ajuste el tipo de bloqueo
                        que desea realizar.<br>
                        Para acceder a la configuración de cookies del navegador para dispositivos <b>Android</b> siga estos pasos
                        (pueden variar en función de la versión del navegador):<br>

                        Ejecute el navegador y pulse la tecla Menú, luego Ajustes.<br>
                        Vaya a Seguridad y Privacidad, verá la opción Aceptar cookies para que active o desactive la
                        casilla.<br>
                        Para acceder a la configuración de cookies del navegador para dispositivos Windows <b>Phone</b> siga estos
                        pasos (pueden variar en función de la versión del navegador):<br>

                        Abra Internet Explorer, luego Más, luego Configuración.<br>
                        Ahora puede activar o desactivar la casilla Permitir cookies.</p>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.axil-signin-form-wrap {
    margin: 80px 0 0 !important;
    height: calc(100vh - 200px);
    padding: 20px;
}

.question {
    font-weight: bold;
    font-size: 16px;
}

.answer {
    margin-top: -10px;
    font-size: 14px;
    text-align: justify;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
    list-style-type: disc;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
    list-style-type: disc;
}</style>