<template>
    <div class="service-area">
        <!--div class="container">
            <div class="row row-cols-xl-4 row-cols-sm-2 row-cols-1 row--20">
                <div class="col">
                    <div class="service-box service-style-2">
                        <div class="icon">
                            <b-img src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/Como_pagar.png" fluid alt="Responsive image"></b-img>
                        </div>
                        <div class="content">
                            <h6 class="title">Elije cómo pagar</h6>
                            <p>En Mainbit.shop, paga con tarjeta, débito o crédito. También puedes pagar en hasta 12
                                mensualidades sin intereses con nuestros bancos participantes.</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="service-box service-style-2">
                        <div class="icon">
                            <b-img src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/Entregas_garantizadas.png" fluid alt="Responsive image"></b-img>
                        </div>
                        <div class="content">
                            <h6 class="title">Entregas garantizadas</h6>
                            <p>Las entregas son garantizadas en todo el territorio nacional.</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="service-box service-style-2">
                        <div class="icon">
                            <b-img src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/Compras_seguras.png" fluid alt="Responsive image"></b-img>
                        </div>
                        <div class="content">
                            <h6 class="title">Atención a clientes</h6>
                            <p>Contamos con una línea telefónica especial para brindarte la atención que mereces y dar
                                seguimiento a tu pedido.

                            </p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="service-box service-style-2">
                        <div class="icon">
                            <b-img src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/Como_pagar.png" fluid alt="Responsive image"></b-img>
                        </div>
                        <div class="content">
                            <h6 class="title">Compras seguras</h6>
                            <p>Seguridad, de principio a fin ¿No te gusta? ¡Devuélvelo! En Mainbit.shop no hay nada que no
                                puedas hacer, porque estás siempre protegido.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div-->
        <!-- Start Footer Area  -->
        <footer class="axil-footer-area footer-style-2">
            <!-- Start Footer Top Area  -->
            <div class="footer-top separator-top">
                <div class="container">
                    <div class="row">
                        <!-- Start Single Widget  -->
                        <div class="col-lg-4 col-sm-6">
                            <div class="axil-footer-widget">
                                <h5 class="widget-title">Soporte</h5>
                                <div class="inner">
                                    <!--p>685 Market Street, <br>
                                    Las Vegas, LA 95820, <br>
                                    United States.
                                    </p-->
                                    <ul class="support-list-item">
                                        <li><a href="mailto:example@domain.com"><i class="fal fa-envelope-open"></i> admin@mainbit.com.mx</a></li>
                                        <li><a href="tel:(+01)850-315-5862"><i class="fal fa-phone-alt"></i>52 55 2700 16 67</a></li>
                                        <!-- <li><i class="fal fa-map-marker-alt"></i> 685 Market Street,  <br> Las Vegas, LA 95820, <br> United States.</li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- End Single Widget  -->
                        <!-- Start Single Widget  -->
                        <div class="col-lg-4 col-sm-6">
                            <div class="axil-footer-widget">
                                <h5 class="widget-title">Cuenta</h5>
                                <div class="inner">
                                    <ul>
                                        <li><router-link :to="{ name: 'Profile' }">Mi cuenta</router-link></li>
                                        <li><router-link :to="{name: 'Sign In'}">Iniciar sesión / Registrarse</router-link></li>
                                        <li><router-link :to="{name: 'Checkout-Cart'}">Carrito</router-link></li>
                                        <li><router-link :to="{name: 'WishList'}">Lista de deseos</router-link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- End Single Widget  -->
                        <!-- Start Single Widget  -->
                        <div class="col-lg-4 col-sm-6">
                            <div class="axil-footer-widget">
                                <h5 class="widget-title">Atención a clientes</h5>
                                <div class="inner">
                                    <ul>
                                        <li><router-link :to="{ name: 'Politicas' }">Política de privacidad</router-link></li>
                                        <li><router-link :to="{ name: 'Condiciones' }">Condiciones de uso</router-link></li>
                                        <li><router-view :to="{name: 'FAQ'}">Preguntas más frecuentes</router-view></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- End Single Widget  -->
                    </div>
                </div>
            </div>
            <!-- End Footer Top Area  -->
        </footer>
        <!-- End Footer Area  -->
        <!-- Start Copyright Area  -->
        <div class="copyright-area copyright-default separator-top">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-4">
                        <div class="social-share">
                            <a href="#"><i class="fab fa-facebook-f"></i></a>
                            <a href="#"><i class="fab fa-instagram"></i></a>
                            <a href="#"><i class="fab fa-twitter"></i></a>
                            <a href="#"><i class="fab fa-linkedin-in"></i></a>
                            <a href="#"><i class="fab fa-discord"></i></a>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-12">
                        <div class="copyright-left d-flex flex-wrap justify-content-center">
                            <ul class="quick-link">
                                <li>© 2023. Todos los derechos reservados por <a target="_blank" href="#">Mainbit.Shop</a>.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-12">
                        <div class="copyright-right d-flex flex-wrap justify-content-xl-end justify-content-center align-items-center">
                            <span class="card-text">Formas de pago:</span>
                            <ul class="payment-icons-bottom quick-link">
                                <li><img src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/Oxxo_pay.png" alt="paypal cart"></li>
                                <li><img src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/Mastercard.png" alt="paypal cart"></li>
                                <li><img src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/Visa.png" alt="paypal cart"></li>
                                <li><img src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/Paypal.png" alt="paypal cart"></li>
                                <li><img src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/American_express.png" alt="paypal cart"></li>
                                <li><img src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/SPEI.png" alt="paypal cart"></li>
                                <li><img src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/BBVA.png" alt="paypal cart"></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Copyright Area  -->
    </div>
</template>