<template>
     <div class="axil-signin-area">
          <!-- Start Header -->
          <div class="signin-header">
               <div class="row align-items-center">
                    <div class="col-sm-4">
                    <router-link :to="{name: 'Index'}" class="site-logo">
                         <img src="@/assets/images/logo/logo.jpeg" alt="logo">
                    </router-link>
                    </div>
                    <!--div class="col-sm-8">
                    <div class="singin-header-btn" v-if="this.$route.name == 'Sign In'">
                         <p>¿Aún no eres cliente?</p>
                         <router-link :to="{name: 'Sign Up' }" class="axil-btn btn-bg-secondary sign-up-btn">Registrate</router-link>
                    </div>
                    </div-->
               </div>
          </div>
          <router-view></router-view>
     </div>
</template>