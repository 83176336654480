<template>
    <div class="container">
        <div class="row">
            <div class="header-brand">
                <router-link :to="{ name: 'Index' }" class="logo logo-dark"
                    style="display: flex;margin-top: 10px;position: absolute;">
                    <img src="@/assets/images/logo/logo.jpeg" alt="Site Logo">
                </router-link>
            </div><br><br>
            <div class="col-lg-6 text-center"><br><br>
                <span class="title-highlighter highlighter-secondary text-center">
                    <i class="fal fa-exclamation-circle text-center"></i> ¡Ups! Falta algo.
                </span><br><br>
                <h1 class="text-left">Página no encontrada</h1><br>
                <router-link :to="{ name: 'Index' }" :v-on="toBack()" class="axil-btn btn-bg-secondary right-icon text-left">Regresando al inicio en: {{ countDown }}</router-link>
            </div>
            <div class="col-lg-6 contenedor">
                <img src="../assets/images/others/error_page.png" alt="404" style="width: 75%;">
                <div class="centrado">{{ status }}</div> 
            </div>
            <div class="section-title-wrapper">
                <span class="title-highlighter highlighter-primary">
                    <i class="far fa-shopping-basket"></i>
                    Productos Destacados
                </span>
                <SplitCategoryReel :Reel="featuredProducts"></SplitCategoryReel>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import SplitCategoryReel from "@/components/Reels/SplitCategoryReel.vue";
import store from '@/store'
export default {
    name: 'notFound',
    components:{
        SplitCategoryReel
    },
    data() {
        return {
            countDown: 10,
            featuredProducts: []
        }
    },
    mounted() {
        this.getFeaturedProducts();
    },
    methods: {
        toBack() {
            if (this.isLoading === false) {
                setTimeout(() => this.$router.push({ name: 'Index' }), 10000);
            }
        },
        countDownTimer() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }
        },
        getFeaturedProducts() {
            store.dispatch('featuredProducts')
                .then(response => {
                    this.featuredProducts = response.data.products.slice(this.featuredProducts.length - 10)
                })
                .catch(error => {
                    console.error('Error fetching featuredProducts:', error);
                });
        }
    },
    created() {
        this.countDownTimer()
    },
    computed: {
        ...mapState(['isLoading','status'])
    },
}
</script>
<style>
.contenedor{
    position: relative;
    display: inline-block;
    text-align: center;
}
.centrado{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    font-size: 190px;
    color: #00bfff87;
}
</style>
