// composables/useAuth.js
import { computed } from 'vue';
import { useLogto } from '@logto/vue';

export function useAuth() {
  const { isAuthenticated, user } = useLogto();

  const authStatus = computed(() => isAuthenticated?.value || false);
  const currentUser = computed(() => (isAuthenticated?.value ? user?.value : null));

  return { authStatus, currentUser };
}
