<template>
    <div class="row">
        <div class="col-lg-12 offset-xl-4">
            <div class="axil-signin-form-wrap">
                <div style="text-align: left;">
                    <h1 class="text-left">TÉRMINOS Y CONDICIONES DE USO</h1>
                    <h5>Última actualización: Noviembre 2023.</h5><br>

                    <p class="answer">Mainbit, S.A. de C.V., (en lo sucesivo <b>“Mainbit.shop”</b>) por medio del presente
                        documento, establece los Términos y Condiciones a los que se sujetarán los clientes o usuarios que
                        accedan y/o usen el sitio web Mainbit.shop El Usuario manifiesta su aceptación de estos Términos y
                        Condiciones de Uso desde el momento en que se registra en el sitio web. Por lo anterior, el usuario,
                        declara que ha leído y acepta los Términos y Condiciones aquí expresados. En el caso de que el
                        Usuario no acepte de forma absoluta los Términos y Condiciones, deberá abstenerse de hacer uso del
                        sitio web Mainbit.shop

                        Los presentes Términos y Condiciones podrían ser modificados en cualquier momento por
                        <b>Mainbit.shop</b>, por lo que el Usuario los deberá consultar de forma periódica en el sitio web
                        <b>Mainbit.shop</b> para conocer las disposiciones vigentes al momento del uso de cualquiera de los
                        servicios. El Usuario acepta y reconoce que Mainbit.shop no asume ninguna obligación o
                        responsabilidad que no se encuentre específicamente descrita en los presentes Términos y
                        Condiciones.
                    </p><br>
                    <h3 style="text-align: left;font-weight: bold;">1. PROCEDIMIENTO PARA LA ADQUISICIÓN DEL BIEN.</h3>
                    <p class="question">1.1 Registro de usuario en <b>Mainbit.shop</b></p>
                    <p class="answer">Para realizar la compra del bien o producto, el usuario deberá ingresar a Mainbit.shop
                        mediante una cuenta de registro existente, o en su defecto, registrarse para crear una nueva cuenta.
                        El usuario deberá proporcionar los datos que se le soliciten en el formato de inscripción,
                        requisitando todos los campos de información solicitados por <b>Mainbit.shop</b>. Es responsabilidad
                        del
                        usuario proporcionar información exacta, precisa y certera, así como actualizar su información
                        cuando corresponda. Como medida de seguridad, el usuario al registrarse deberá elaborar una
                        contraseña.<br>
                        El uso de la misma es de exclusiva responsabilidad del usuario, por lo cual se recomienda no
                        compartir esta información.<br>

                        Puedes llevar a cabo tu registro aquí. <b>(Implementar un acceso directo al formato de
                            registro.)</b> Los
                        datos proporcionados por el usuario, en términos de la Ley Federal de Protección de Datos Personales
                        en Posesión de Particulares, serán considerados datos personales, por lo cual su tratamiento se
                        restringirá a lo señalado en el Aviso de Privacidad que puede ser consultado en la siguiente
                        referencia electrónica: <b>(establecer link que envíe al aviso)</b><br>

                        Además, en cumplimiento del artículo 76 Bis, fracción I, de la Ley Federal de Protección al
                        Consumidor, <b>Mainbit.shop</b> se compromete a utilizar la información proporcionada por el usuario
                        en
                        forma confidencial, por lo que no podrá difundirla o transmitirla a terceros ajenos a la
                        transacción, salvo autorización expresa del propio usuario o por requerimiento de autoridad
                        competente.<br>

                        <b>Mainbit.shop</b> se obliga a que el usuario, previo a la elección del bien, tenga acceso a la
                        siguiente
                        información:<br>
                    </p>

                    <ol>
                        <li>El costo total de la transacción comercial, incluidos los descuentos disponibles y todos los
                            cargos, así como los métodos de pago y facturación.</li>
                        <li>Los impuestos adicionales al IVA que en su caso apliquen.</li>
                        <li>En su caso, el costo de envío.</li>
                        <li>En su caso, los cargos adicionales derivado del método de pago que se utilice.</li>
                        <li>En su caso, la frecuencia de pagos y cualquier condición asociada con el mismo, incluyendo
                            restricciones o condiciones aplicables.</li>
                    </ol>

                    <p class="question">1.2 Información del bien.</p>
                    <p class="answer"><b>Mainbit.shop</b> manifiesta que la información que se proporciona sobre los bienes
                        ofertados en venta, es veraz, comprobable, clara y se encuentra exenta de textos, diálogos, sonidos,
                        imágenes o alguna otra figura que induzca o pueda inducir a error o confusión al usuario.<br>

                        Por lo anterior, <b>Mainbit.shop</b> deberá señalar el lugar de origen de los bienes o productos en
                        venta,
                        incluyendo los lugares en donde pueden repararse, así como las instrucciones para su uso y las
                        garantías correspondientes.<br>

                        <b>Mainbit.shop</b> manifiesta que la información de los bienes ofrecidos en venta contiene al menos
                        los
                        siguientes datos:<br>
                    </p>

                    <ol>
                        <li>Descripción del bien, incluyendo sus especificaciones (dimensiones, funcionalidades, calidad, o
                            en su caso los materiales de su fabricación, la indicación si se trata de un bien nuevo,
                            reparado,
                            reconstruido o usado. MAINBIT, S.A. DE C.V. garantizará que los proveedores que utilicen sus
                            servicios para la comercialización de productos, incluirán toda la información respecto de los
                            bienes ofertados.</li>
                        <li>La disponibilidad y existencias del bien.</li>
                        <li>Plazo de vigencia de oferta y promociones.</li>
                        <li>El monto total a pagar en moneda nacional y cualquier otro cargo adicional (bonificaciones en
                            efectivo, premios, descuentos y vigencia de los mismos).</li>
                        <li>Deberá informar el plazo de las garantías así como las restricciones aplicables.</li>
                        <li>La forma de entrega del bien, incluyendo los costos, plazos y opciones de envío; así como la
                            responsabilidad de los prestadores de servicios de mensajería y paquetería cuando sean
                            utilizados
                            dichos servicios</li>
                        <li>Las demás condiciones aplicables establecidas en el artículo 7 de la Ley Federal de Protección
                            al
                            Consumidor</li>
                    </ol>

                    <p class="answer">En consecuencia, el usuario manifiesta que es conocedor de las especificaciones,
                        características,
                        condiciones, términos, plazos de entrega y precio total que aplican al bien elegido.<br>

                        No obstante lo anterior, <b>Mainbit.shop</b> manifiesta que el usuario podrá realizar cambios en
                        cualquier
                        selección de bienes, así también el usuario podrá consultar la información sobre el bien antes de
                        aceptar la transacción.<br>

                        Para concretar la compraventa del bien, el usuario, dentro de <b>Mainbit.shop</b>, deberá manifestar
                        su
                        consentimiento para la adquisición del mismo, eligiendo la opción correspondiente a la compra del
                        bien seleccionado, posteriormente deberá seleccionar la opción de forma de pago que más le
                        favorezca, mismas que se encuentran señaladas en el Anexo I de los presentes términos y
                        condiciones.<br>

                        <b>Mainbit.shop</b> deberá entregar al usuario el comprobante de la transacción realizada, en el
                        cual se
                        deben considerar al menos los siguientes puntos:
                    </p><br>

                    <ol>
                        <li>Generar el número de identificación de la transacción comercial una vez que la transacción sea
                            autorizada.</li>
                        <li>Proporcionar el número de la transacción comercial, detalles de la misma y la dirección de envío
                            del bien comprado.</li>
                        <li>Permitir el seguimiento de la transacción comercial, a través del número de transacción o número
                            de cuenta en <b>Mainbit.shop</b>.</li>
                        <li>Informar los medios de contacto o ayuda en caso de modificaciones o errores.</li>
                        <li>El medio en que se entregarán o pondrán a disposición del Consumidor el comprobante fiscal
                            (factura electrónica) y/o el Comprobante de la Transacción, los cuales se emitirán sin ningún
                            costo
                            para el consumidor.</li>
                    </ol>


                    <p class="answer">A solicitud del usuario, <b>Mainbit.shop</b> además deberá entregar el comprobante
                        fiscal queampare la transacción comercial de mérito.<br>

                        <b>Mainbit.shop</b> manifiesta que las condiciones de entrega del bien adquirido se encuentran
                        determinadas
                        en el Anexo II de estos términos y condiciones.
                    </p><br>

                    <h3 style="text-align: left;font-weight: bold;">2. PUBLICIDAD.</h3><br>
                    <p class="answer">El Usuario reconoce y acepta que <b>Mainbit.shop</b> es una organización independiente
                        de
                        terceros patrocinadores y anunciantes cuya información, imágenes, anuncios y demás material
                        publicitario o promocional (el "Material Publicitario") pudiera estar publicado en el Sitio web. El
                        Usuario reconoce y acepta que el Material Publicitario no forma parte del contenido principal que se
                        publica en el Sitio web. Asimismo, el Usuario reconoce y acepta en este acto, que el Material
                        Publicitario se encuentra protegido por las leyes que en materia de propiedad intelectual e
                        industrial resulten aplicables.
                    </p><br>
                    <h3 style="text-align: left;font-weight: bold;">3. DERECHOS DE AUTOR Y PROPIEDAD INTELECTUAL.</h3><br>
                    <p class="answer"><b>Mainbit.shop</b>, y sus proveedores y otorgantes de licencias se reservan todos los
                        derechos intelectuales en todo el texto, programas, productos, procesos, tecnología, contenido y
                        otros materiales que aparecen en las herramientas electrónicas de <b>Mainbit.shop</b>.
                        Los nombres y logos de <b>Mainbit.shop</b>, todos los productos relacionados y nombres de servicios,
                        marcas
                        de diseño y avisos comerciales son marcas registradas o marcas de servicio registradas o licenciadas
                        a nombre de Mainbit, S.A. de C.V. Todas las otras marcas son propiedad de sus respectivas compañías.
                        Ninguna licencia de marca registrada o marca de servicio está concedida en relación con los
                        materiales contenidos en sus herramientas electrónicas.

                        El uso del sitio web de <b>Mainbit.shop</b>, no autoriza a nadie a utilizar cualquier nombre, logo o
                        marca
                        en ninguna forma.

                        Asimismo, el contenido de bases de datos, redes, archivos que permiten al Usuario acceder y usar su
                        Cuenta, son propiedad de <b>Mainbit.shop</b> y están protegidas por las Leyes y tratados
                        internaciones de
                        derecho de autor, marcas, patentes, modelos y diseños industriales. El uso indebido y la
                        reproducción total o parcial de dichos contenidos quedan prohibidos, salvo autorización expresa y
                        por escrito de <b>Mainbit.shop</b>.
                    </p><br>

                    <h3 style="text-align: left;font-weight: bold;">4. MARCAS REGISTRADAS.</h3><br>
                    <p class="answer">A menos que de forma expresa y por escrito <b>Mainbit.shop</b> indicara lo contrario,
                        todos
                        los materiales, incluyendo imágenes, las ilustraciones, los diseños, los íconos, las fotografías y
                        los materiales escritos, las marcas registradas, la combinación de signos distintivos, elementos
                        operativos y de imagen y/o la característica intelectual poseída, controlada o licenciada por
                        <b>Mainbit.shop</b>, o por los terceros que han licenciado sus materiales a <b>Mainbit.shop</b> son
                        protegidos por
                        la legislación Mexicana y los tratados internacionales en materia de Propiedad Industrial e
                        intelectual que resulten aplicables.

                        La compilación (que significa la mutilación, colección, el arreglo, armado del todo o partes) del
                        contenido en las herramientas electrónicas de <b>Mainbit.shop</b>, también es protegida por la
                        legislación
                        mexicana y los tratados internacionales que correspondan.

                        <b>Mainbit.shop</b>, y sus proveedores y otorgantes de licencias se reservan todos los derechos
                        intelectuales en todo el texto, programas, productos, procesos, tecnología, contenido y otros
                        materiales que aparecen en las herramientas electrónicas de<b>Mainbit.shop</b>.

                        Los nombres y logos de <b>Mainbit.shop</b>, todos los productos relacionados y nombres de servicios,
                        marcas
                        de diseño y avisos comerciales son marcas registradas o marcas de servicio registradas o licenciadas
                        a nombre de Mainbit, S.A. de C.V. Todas las otras marcas son propiedad de sus respectivas compañías.
                        Ninguna licencia de marca registrada o marca de servicio está concedida en relación con los
                        materiales contenidos en sus herramientas electrónicas.
                    </p><br>

                    <h3 style="text-align: left;font-weight: bold;">5. AVISOS COMERCIALES.</h3><br>
                    <p class="answer">El Usuario entiende y acepta que en virtud de la aceptación de estos Términos y
                        Condiciones igualmente acepta recibir información comercial de<b>Mainbit.shop</b> vía correo
                        electrónico.
                        De igual manera, el Usuario puede informar a <b>Mainbit.shop</b>, para el caso de que desee dejar de
                        recibir información de promociones y publicidad de productos y servicios, enviando su solicitud a la
                        Oficina de “Protección de Datos”, a través de la cuenta de correo electrónico
                        privacidad@mainbit.shop <b>Mainbit.shop</b> emplea cookies, web beacons y otras tecnologías de
                        rastreo y
                        seguimiento para monitorear su comportamiento durante la navegación del Usuario en el Sitio, ello
                        con la finalidad de proporcionar un mejor servicio, presentar publicidad y ofertas, personalizar la
                        experiencia del Usuario en el Sitio, así como poder ofrecerle nuevos productos y servicios basados
                        en sus preferencias.

                        Los datos personales que obtenemos con estas tecnologías de rastreo consisten en: hábitos de
                        navegación en Internet, horario y tiempo de navegación en el Sitio, secciones consultadas y páginas
                        de Internet que han sido accedidas previamente al Sitio. En esta cuestión, el Usuario debe
                        asegurarse que la configuración de su computadora sea acorde a si está dispuesto a recibir cookies o
                        no. Puede configurar su navegador para que le advierta antes de aceptar cookies, o simplemente puede
                        configurarlo para que las rechace.
                    </p><br>

                    <h3 style="text-align: left;font-weight: bold;">6. ENLACE A OTROS SITIOS.</h3><br>
                    <p class="answer">Este sitio web puede tener conexiones con sitios web controlados por terceros ajenos a
                        <b>Mainbit.shop</b>. <b>Mainbit.shop</b> NO será responsable por los contenidos, materiales,
                        acciones
                        y/o
                        servicios prestados por los mismos, ni por daños o pérdidas ocasionadas por la utilización de los
                        mismos, sean causados directa o indirectamente, ni por el tipo de contenido, por ningún tipo de
                        errores o demoras en el contenido, su veracidad o por cualquier acción tomada, así como por
                        retrasos, interrupciones o errores, fallas u omisiones que sean ocasionados por o en la captura,
                        proceso, transmisión, recepción o exhibición de la información, así como de su interpretación.

                        La presencia de enlaces a otros sitios web no implica una sociedad, relación, aprobación o respaldo
                        de <b>Mainbit.shop</b> a dichos sitios web y sus contenidos.
                    </p><br>

                    <h3 style="text-align: left;font-weight: bold;">7. MODIFICACIONES SITIO WEB.</h3><br>
                    <p class="answer"><b>Mainbit.shop</b> se reserva el derecho de modificar o cambiar el contenido de este
                        sitio
                        web en cualquier momento y sin previo aviso, en ningún caso <b>Mainbit.shop</b> será responsable por
                        supuestos daños o pérdidas sufridas por los usuarios de este sitio web sufridos con motivo de dichos
                        cambios o modificaciones.<br>

                        Las personas que consulten y/o utilicen el sitio web de <b>Mainbit.shop</b> por el simple hecho de
                        hacerlo,
                        aceptan y se obligan en los términos y condiciones de responsabilidad aquí descritos.
                    </p><br>

                    <h3 style="text-align: left;font-weight: bold;">8. AVISO DE PRIVACIDAD.</h3><br>
                    <p class="answer">De conformidad con lo dispuesto en la Ley Federal de Protección de Datos Personales en
                        Posesión de los Particulares y su Reglamento (en lo sucesivo la “LFPDPPP”); Usted expresamente
                        reconoce y acepta que cualesquiera datos personales comunicados a Mainbit.shop serán protegidos y
                        tratados conforme a lo dispuesto por la LFPDPPP y de acuerdo con lo establecido en nuestro Aviso de
                        Privacidad Integral. Ver Aviso de Privacidad (<b>establecer link que envíe al aviso</b>)
                    </p><br>

                    <h3 style="text-align: left;font-weight: bold;">9. GARANTÍA DE PRODUCTOS.</h3><br>
                    <p class="answer">La garantía respecto de los productos adquiridos se hará válida directamente con el
                        fabricante del artículo, de conformidad con sus políticas, pólizas de garantía, procedimientos y
                        restricciones. Debido a que estos artículos cuentan con garantía por escrito expedida por los
                        fabricantes, son ellos quienes asumen por escrito la obligación de responder por sus políticas,
                        pólizas de garantía, procedimientos y restricciones de conformidad con el artículo 79 de la Ley
                        Federal de Protección al Consumidor.<b>Ver Anexo III (establecer link que envíe al Anexo de
                            Garantías)</b>
                    </p><br>

                    <h3 style="text-align: left;font-weight: bold;">10. ATENCIÓN A USUARIOS.</h3><br>
                    <p class="answer">Dudas o mayor información, te recomendamos contactarnos a través de nuestro Centro de
                        Atención al teléfono o mediante correo electrónico.
                    </p><br>

                    <h3 style="text-align: left;font-weight: bold;">11. FALLAS EN EL SISTEMA.</h3><br>
                    <p class="answer"><b>Mainbit.shop</b> no se responsabiliza por cualquier daño, perjuicio o pérdida al
                        Usuario
                        causados por fallas en el sistema, en el servidor o en Internet no imputables a <b>Mainbit.shop</b>.
                        <b>Mainbit.shop</b> tampoco será responsable por cualquier virus que pudiera infectar el equipo del
                        Usuario
                        como consecuencia del acceso a internet del Usuario, uso o examen de su sitio web o a raíz de
                        cualquier transferencia de datos, archivos, imágenes, textos, o audios contenidos en el mismo no
                        imputables a <b>Mainbit.shop</b>. Los Usuarios NO podrán imputarle responsabilidad alguna ni exigir
                        pago
                        por lucro cesante, en virtud de perjuicios resultantes de dificultades técnicas o fallas en los
                        sistemas o en Internet de los que <b>Mainbit.shop</b> no sea responsable. El sistema puede
                        eventualmente no
                        estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra
                        circunstancia ajena a <b>Mainbit.shop</b>; en tales casos se procurará restablecerlo con la mayor
                        celeridad
                        posible sin que por ello pueda imputársele algún tipo de responsabilidad.<br>

                        Asimismo, <b>Mainbit.shop</b> hace del conocimiento del Usuario que para brindar seguridad y
                        confidencialidad a la información que proporcione, los elementos técnicos disponibles utilizados por
                        <b>Mainbit.shop</b> son los siguientes: a) Aviso de Privacidad. d) Aceptación de los Términos y
                        Condiciones.
                    </p><br>

                    <h3 style="text-align: left;font-weight: bold;">12. SANCIONES.</h3><br>
                    <p class="answer">En caso de que el Usuario incumpliera una ley o los Términos y Condiciones, podremos
                        advertir, suspender, restringir o inhabilitar temporal o definitivamente su cuenta, sin perjuicio de
                        otras sanciones que se establezcan en los Términos y Condiciones de <b>Mainbit.shop</b>.
                    </p><br>

                    <h3 style="text-align: left;font-weight: bold;">13. LEGISLACIÓN APLICABLE Y JURISDICCIÓN.</h3><br>
                    <p class="answer">Los presentes Términos y Condiciones estarán regidos en todos sus puntos por las leyes
                        vigentes en la República Mexicana.
                    </p><br>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.axil-signin-form-wrap {
    margin: 80px 0 0 !important;
    height: calc(100vh - 200px);
    padding: 20px;
}

.question {
    font-weight: bold;
    font-size: 16px;
}

.answer {
    margin-top: -10px;
    font-size: 14px;
    text-align: justify;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
    list-style-type: disc;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
    list-style-type: disc;
}

ol li {
    font-size: var(--font-size-b1);
    line-height: var(--line-height-b1);
    color: var(--color-body);
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 14px;
}
</style>