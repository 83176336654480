<template>
    <!-- Start Mainmenu Area  -->
    <div class="axil-mainmenu fixed-nav" v-on="totalTemp()" @click="checkLogin()">
        <div class="container">
            <div class="header-navbar">
                <div class="header-brand">
                    <router-link :to="{ name: 'Index' }" class="logo logo-dark">
                        <img src="@/assets/images/logo/logo.jpeg" alt="Site Logo">
                    </router-link>
                    <router-link :to="{ name: 'Index' }" class="logo logo-light">
                        <img src="@/assets/images/logo/logo.jpeg" alt="Site Logo">
                    </router-link>
                </div>
                <div class="header-main-nav" :class="showNavMenu ? 'open' : ''">
                    <!-- Start Mainmanu Navv -->
                    <nav class="mainmenu-nav">
                        <button class="mobile-close-btn mobile-nav-toggler" @click="openNavMobileMenu()">
                            <i class="fas fa-times"></i>
                        </button>
                        <div class="mobile-nav-brand">
                            <router-link :to="{ name: 'Index' }" class="logo">
                                <img src="@/assets/images/logo/logo.jpeg" alt="Site Logo">
                            </router-link>
                        </div>
                        <p v-if="Error">
                            {{ Error }}
                        </p>
                        <ul v-if="isLoading">
                            <div>
                                <b-spinner variant="info" class="position-loader" type="grow"
                                    label="Loading..."></b-spinner>
                            </div>
                        </ul>
                        <ul v-else class="mainmenu">
                            <li v-if="!isLogged" class="menu-item">
                                Hola, {{ name }} !
                            </li>
                            <b-button v-for="(category, index) in Categories" :key="index" v-b-toggle="'collapse-' + index"
                                class="m-1" variant="primary">
                                <li class="menu-item">{{ category.translations.name }}</li>
                                <b-collapse :id="'collapse-' + index">
                                    <b-card>
                                        <li v-for="(subCategory, ix) in SubCategories" :key="ix" class="menu-item">
                                            <router-link
                                                :to="{ name: 'Category', params: { 'slug': subCategory.translations.slug } }"
                                                v-if="category.id === subCategory.parent_id">
                                                {{ subCategory.translations.name }}
                                            </router-link>
                                        </li>
                                    </b-card>
                                </b-collapse>
                            </b-button>
                        </ul>
                    </nav>
                    <!-- End Mainmanu Nav -->
                </div>
                <div class="header-action">
                    <ul class="action-list">
                        <li class="axil-search">
                            <a @click.prevent="openSearch" class="header-search-icon" title="Search">
                                <i class="flaticon-magnifying-glass"></i>
                            </a>
                        </li>
                        <li class="shopping-cart" v-if="!isLogged">
                            <a @click.prevent="openSideCartBar" class="cart-dropdown-btn">
                                <span class="cart-count" v-if="cart && cart.items_qty > 0">{{ cart.items_qty }}</span>
                                <i class="flaticon-shopping-cart"></i>
                            </a>
                        </li>
                        <li class="shopping-cart" v-if="isLogged">
                            <a @click.prevent="openSideCartBar" class="cart-dropdown-btn">
                                <span class="cart-count" v-if="productsCartTemp.length > 0">{{ total }}</span>
                                <i class="flaticon-shopping-cart"></i>
                            </a>
                        </li>
                        <li class="my-account">
                            <div class="dropdown" tabindex="0">
                                <button class="dropdown-btn" aria-haspopup="menu" @click="showDropDown = !showDropDown">
                                    <span><i class="flaticon-person" style="font-size:22px"></i></span>
                                    <span class="arrow"></span>
                                </button>
                                <ul class="dropdown-content" role="menu">
                                    <li v-if="!isLogged"><router-link :to="{ name: 'My Profile' }">Mi cuenta</router-link></li>
                                    <li v-if="!isLogged"><router-link :to="{ name: 'Orders' }">Mis pedidos</router-link>
                                    </li>
                                    <li v-if="!isLogged"><router-link :to="{ name: 'WishList' }">Favoritos</router-link>
                                    </li>
                                    <li v-if="!isLogged"><router-link :to="{ name: 'Addresses' }">Direcciones</router-link>
                                    </li>
                                    <li v-if="isLogged"><router-link :to="{ name: 'Sign In' }" class="sign-in">Identificarse</router-link></li>
                                    <li v-if="!isLogged"><a href="#modal-session" style="cursor: pointer;color: red;">Cerrar
                                            sesión</a></li>
                                    <li v-if="isLogged"><button @click="redirectToRegister()" class="sign-up">Soy un cliente nuevo</button></li>
                                </ul>
                            </div>
                        </li>
                        <li class="axil-mobile-toggle">
                            <button class="menu-btn mobile-nav-toggler" @click="openNavMobileMenu()">
                                <i class="flaticon-menu-2"></i>
                            </button>
                        </li>
                    </ul>
                    <!-- Mostrar token para depuración -->
                    <span class="text-bienvenida" v-if="!isLogged && name">{{ "Hola, " + name }}</span>
                </div>
                <div v-if="showMask" class="closeMask" @click="openNavMobileMenu()"></div>
                <div class="modal" id="modal-session">
                    <a href="#" class="modal-bg"></a>
                    <div class="modal-content">
                        <a href="#" class="modal-exit"><i class="fas fa-times"></i></a>
                        <h3 class="modal-title axil-breadcrumb-item">¿Estás seguro que quieres salir de la sesión?</h3>
                        <div class="modal-text">

                            <div class="row">
                                <b-spinner v-if="isLoading" variant="info" class="position-loader"
                                    label="Loading..."></b-spinner>
                                <div class="col-6" v-else>
                                    <b-button variant="light">
                                        <a class="cancel-modal" href="#">Cancelar</a>
                                    </b-button>
                                </div>
                                <div class="col-6">
                                    <b-button variant="success" @click="handleLogOut">Aceptar</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <CartDropdown :openDialogCart="showDialogCart" @changeDialogCart="openSideCartBar" :cart="cart" />
    <SearchDropdown :openSearch="showSearch" @changeSearch="openSearch" />
    <ProfileDropdown :openProfile="showProfile" @changeProfile="openProfile" />
    <!-- End Mainmenu Area -->
</template>

<script>
import CartDropdown from "./CartDropdown.vue"
import SearchDropdown from "./SearchDropdown.vue"
import ProfileDropdown from "./SearchDropdown.vue"
import store from '@/store/index.js'
//import router from "@/router";
import { mapState } from 'vuex'
import { useLogto } from '@logto/vue';
import { useAuth } from '@/composables/useAuth';

export default {
    name: 'NavBar',
    props: {
        Categories: {
            required: true,
            type: Array
        },
        user: {
            required: false
        },
        cart: {
            required: false
        },
        SubCategories: {
            required: true,
            type: Array
        },
        Error: {
            required: true
        },
    },
    components: {
        CartDropdown,
        SearchDropdown,
        ProfileDropdown
        // Slide,
    },
    data: () => ({
        currentUser: useAuth(),
        showNavMenu: false,
        showMask: false,
        showDialogCart: false,
        showSearch: false,
        showProfile: false,
        showDropDown: false,
        welcome: " ",
        total: 0,
        isValidToken: false,
        token: null, // Estado del token
        tokenExpired: false
    }),
    computed: {
        isLogged() {
            if (!store.state.user.token) {
                return true;
            }
            return false;
        },
        ...mapState(['isLoading', 'productsCartTemp', 'productsTemp','name'])
    },
    mounted() {
        this.token = store.state.user.token; 
        this.tokenExpired = store.state.user.tokenExpired
        if (store.state.user.token) {
            this.getProfile();
        }
    },
    created() {
        this.logto = useLogto();
    },
    methods: {
        checkLogin(){
            if(this.tokenExpired){
                const accessToken = this.logto.getAccessToken('https://api.mainbitpreproduccion.shop');
                if (!accessToken.success) {
                    return this.handleLogOut();
                }
                sessionStorage.setItem('TOKEN', accessToken);
                store.state.user.token = accessToken;
                store.state.email = this.userDataFromLogTo.email;
                store.dispatch('userProfile', {
                    accessToken: accessToken, 
                    email: this.userDataFromLogTo.email
                })
            }
        },  
        handleLogin() {
            const exampleToken = store.state.user.token; 
            this.token = exampleToken;
        },
        handleLogOut() {
            this.logto.clearAccessToken();
            this.logto.clearAllTokens();
            store.state.tokenExpired = true;
            sessionStorage.removeItem('TOKEN');
            sessionStorage.removeItem('REFRESH_TOKEN');
            //this.logto.signOut('http://localhost:8080/');
            this.logto.signOut('https://mobile.mainbitpreproduccion.shop/');
        },
        handleRegister() {
            console.log(this.$logto); // Verifica la instancia
            const redirectUri = 'http://localhost:8080/callback';
            const scopes = 'openid profile email';
            this.logto.signUp(redirectUri, { scope: scopes }); // Verifica si existe el método
        },
        totalTemp() {
            if (this.isLogged && this.productsCartTemp.length > 0) {
                this.total = this.productsCartTemp.reduce((a, b) => a + parseInt(b.quantity), 0);
                return this.total
            }
        },
        openNavMobileMenu() {
            this.showNavMenu = !this.showNavMenu
            this.showMask = !this.showMask
        },
        addClassForMainMenu() {
            let itemRef = this.$refs['itemMenu-1']
            let subRef = this.$refs['subMenu-1']
            // this.$ref['subMenu-1'].style
            if (subRef.style.display == "") {
                itemRef.classList.value = 'menu-item-has-children open'
                subRef.style.display = "block"
            } else {
                subRef.style.display = ""
                itemRef.classList.value = 'menu-item-has-children'
            }
        },
        openSideCartBar() {
            this.showDialogCart = !this.showDialogCart
            this.showMask = !this.showMask
        },
        openSearch() {
            this.showSearch = !this.showSearch
            this.showMask = !this.showMask
        },
        openProfile() {
            this.showProfile = !this.showProfile
        },
        getProfile(){
            store.dispatch('getUser');
        },
        redirectToRegister() {
            try {
                // URL de registro de Logto
                const registerUrl = this.$logtoEndpoint + 'register';
                
                // Redirige al usuario a la URL de registro
                window.location.href = registerUrl;
            } catch (error) {
                // Manejo de errores
                this.errorMessage = error.message || 'An error occurred during redirection.';
                console.error('Error during redirection:', error.message || error);
            }
        }
            // getCategories() {
        //     store.dispatch("getCategories")
        //     .then(response => {
        //         console.log('Categories:', response.data);
        //         this.categories = response.data;
        //     })
        //     .catch(error => {
        //         console.error('Error fetching Categories:', error);
        //     })
        //     .finally(() => {
        //         this.loading = false;
        //     });
        // },
    }, // methods
};
</script>
<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.bm-overlay {
    background: transparent;
}

.bm-burger-button {
    position: absolute;
    width: 22px;
    height: 16px;
    left: 90.5%;
    top: 36px;
    cursor: pointer;
}

.bm-burger-bars {
    background-color: #303037;
}

.bm-menu {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: #eaeaeb;
    overflow-x: hidden;
    padding-top: 60px;
    -webkit-transition: .5s;
    transition: .5s;
}

.bm-item-list>*>span {
    margin-left: 10px;
    font-weight: 700;
    color: #000;
}

.closeMask {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 6;
    transition: .3s;
}

.header-main-nav.open .mainmenu-nav .mainmenu li:nth-child(1n) {
    transition-delay: .3s;
}

.header-main-nav.open .mainmenu-nav .mainmenu li {
    transform: translateY(0);
    opacity: 1;
}

@media only screen and (max-width: 991px) {
    .header-main-nav .mainmenu-nav .mainmenu>li.menu-item-has-children .axil-submenu {
        display: none;
        position: static;
        transform: scaleY(1);
        visibility: visible;
        opacity: 1;
        min-width: auto;
        box-shadow: none;
        padding: 0;
        transition: initial;
    }
}

.btn-primary {
    --bs-btn-color: #000;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: none;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: none;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: none;
}

.modal-dialog {
    position: relative;
    width: auto;
    pointer-events: none;
    margin: 3%;
}

.modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: right;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
    background-color: var(--bs-modal-footer-bg);
    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.position-loader {
    position: absolute;
    top: 50%;
    left: 50%;
}

.text-bienvenida {
    font-size: 15px;
    color: #5588bc;
    text-transform: capitalize;
}

.dropdown {
    max-width: 13em;
    position: relative;
    width: 100%;
}

.dropdown-btn {
    font-size: 18px;
    width: 100%;
    border: none;
    color: #848691;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7em 0.1em;
    border-radius: 0.5em;
    cursor: pointer;
    background-color: transparent;
}

.arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #fff;
    transition: transform ease-in-out 0.3s;
}

.dropdown-content {
    list-style: none;
    position: absolute;
    width: 155px;
    visibility: hidden;
    overflow: hidden;
    left: -30px;
}

.dropdown-content li {
    position: relative;
    transition: 0.5s;
    transition-delay: calc(60ms * var(--delay));
}

.dropdown:focus-within .dropdown-content li {
    left: 0;
}

.dropdown:focus-within .dropdown-content {
    visibility: visible;
}

.dropdown:focus-within .dropdown-btn>.arrow {
    transform: rotate(180deg);
}

.dropdown-content li:hover {
    background: #1d1f24;
}

.dropdown-content li a {
    display: block;
    padding: 0em 0em;
    color: #000;
    margin: 0.1em 0;
    text-decoration: none;
    width: 120px;
    font-size: 12px;
}

.sign-in {
    color: #e72043 !important;
    text-decoration: underline !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.sign-up {
    color: #1a76af !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.dropdown {
    display: inline-block;
    position: relative;
}



.dd-button {
    display: inline-block;
    padding: 10px 0px 10px 0px;
    cursor: pointer;
    white-space: nowrap;
    margin-right: 10px;
}

.dd-button:after {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
}

.dd-button:hover {
    background-color: transparent;
}


.dd-input {
    display: none;
}

.dd-menu {
    position: absolute;
    top: 100%;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    padding: 0;
    /* margin: 2px 0 0 0; */
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    list-style-type: none;
    margin-left: -125px;
}

.dd-input+.dd-menu {
    display: none;
}

.dd-input:checked+.dd-menu {
    display: block;
}

.dd-menu li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
}

.dd-menu li:hover {
    background-color: #f6f6f6;
}

.dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
}

.dd-menu li.divider {
    padding: 0;
    border-bottom: 1px solid #cccccc;
}

.fixed-nav {
    position: fixed;
    z-index: 10;
    background: #fff;
    width: 100%;
    padding: 0px 5px !important;
}

/*.dropdown {
    max-width: 13em;
    position: relative;
    width: 100%;
}

.dropdown-btn {
    font-size: 18px;
    width: 100%;
    border: none;
    color: #000;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #fff;
    transition: transform ease-in-out 0.3s;
}

.dropdown-content {
    list-style: none;
    position: absolute;
    width: 100%;
    visibility: hidden;
    transform: translateY(-1em);
    transition: transform ease 0.3s;
    min-width: 170px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-left: -86px;
}

input[type="checkbox"]:checked~.dropdown-content {
    visibility: visible;
    transform: translateY(0);
}

.dropdown-content li {
    border-radius: 0.5em;
}

input[type="checkbox"]:checked+label>.arrow {
    transform: rotate(180deg);
}

input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    display: none;
}

input[type=checkbox].hidden {
    opacity: 0;
}

.hidden {
    display: none;
}

.dropdown-content li a {
    display: block;
    padding: 0.1em 0.5em;
    color: #000;
    margin: 0.1em 0;
    text-decoration: none;
    margin-left: -20px;
}*/</style>